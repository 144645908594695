import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AppBar, AppBarDivider } from "../../../components/muiCore/Paper";
import { AppBarSearch } from "../../../components/muiCore/InputBase";
import { CalendarsDateRangePicker } from "../../../components/muiCore/DateRangePicker";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  GridSize,
  IconButton,
  makeStyles,
  Switch,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  OutcomeCard,
  SelectableCard,
  SelectableCardIconContentItem,
  SelectableStatisticCard,
  SkeletonCard,
} from "../../../components/muiCore/Card";
import { Skeleton, ToggleButtonGroup } from "@material-ui/lab";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import {
  endOfToday,
  format,
  parse,
  parseISO,
  startOfToday,
  startOfYesterday,
} from "date-fns";
import { Drawer } from "../../../components/muiCore/Drawer";
import {
  OutcomeDetailsListItem,
  OutcomeHeaderListItem,
} from "../../../components/muiCore/ListItem";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import {
  ClinicMedicalSolid,
  UserMdSolid,
  UserMinusSolid,
  UserPlusSolid,
  UserSolid,
} from "../../../components/Icons/DoHIcons";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";

import CommentIcon from "@material-ui/icons/Comment";
import HomeIcon from "@material-ui/icons/Home";
import PhoneIcon from "@material-ui/icons/Phone";
import EventIcon from "@material-ui/icons/Event";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import { AppBarIconButton } from "../../../components/muiCore/Button";
import { PersonAvatar } from "../../../components/muiCore/Avatar";
import CloseIcon from "@material-ui/icons/Close";
import {
  StyledFTextField,
  ValidationTextField,
} from "../../../components/muiCore/TextField";
import PostAddIcon from "@material-ui/icons/PostAdd";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useSnackbar } from "notistack";
import { Context } from "../../../state/Store";
import { QueueWorkItemTabs } from "./QueueWorkItem/QueueWorkItemTabs";
import { SkeletonTimeLine } from "../../../components/muiCore/TimeLine";
import TooltipToggleButton from "../../../components/muiCore/ToggleButton";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import DialogContentText from "@material-ui/core/DialogContentText";
import * as api from "../../../data/api";
import { HubConnection } from "@microsoft/signalr";
import { useSignalR } from "../../utils/signalr";
import { useApiClient } from "../../../common/apiclients";
import {
  CSVExportViewModel,
  LoginUserViewModel,
  OutcomeIdentifier,
  PersonKind,
  PersonsClient,
  QuestionChoice,
  QuestionnaireClient,
  QuestionnairesClient,
  QueueChoiceVm,
  QueueClient,
  QueueIndex,
  QueueOutcomeViewModel,
  QueueViewModel,
  QueueWorkItemStatus,
  QueueWorkItemViewModel,
  TeamClient,
  TeamViewModel,
} from "../../../common/client";
import { AssignToQueueDialog } from "./Queue/AssignToQueueDialog";
import CbxTree from "../../../components/core/Manager/SubComponents/CheckboxTree";
import { QueueQuestionnaireDrawer } from "./Queue/QueueQuestionnaireDrawer";
import { useParams } from "react-router";
import { PersonDetailsFormDef } from "../../../components/core/forms/PersonDetails";
import {
  FDateField,
  FTextField,
} from "../../../common/formComponents/FTextField";
import { QuestionItemYesNoButtons } from "../../../components/core/QuestionSlider/QuestionItemYesNoButtons";
import useInfiniteScroll from "react-infinite-scroll-hook";
import deepEqual from "fast-deep-equal/es6";
import { useDebounce } from "../../../common/debounce";
import { buildGroup, useControlValue } from "@react-typed-forms/core";
import { FCheckbox } from "../../../common/formComponents/FCheckbox";
import { saveAs } from "file-saver";
import { RolesSoild } from "../../../components/core/Manager/Icons/Icons";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { couldStartTrivia } from "typescript";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderRadius: "25px",
      maxHeight: "45px",
      marginTop: "5px",
      alignSelf: "",
    },

    excludeAccordion: {
      "& .MuiAccordionSummary-root": {
        height: "50px!important"
      }
    }
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface SearchFilter {
  before?: Date;
  after?: Date;
  queryText: string;
  status: QueueWorkItemStatus[];
  outcomes?: string[];
  queueId: string;
  departments?: string[];
  excluded?: string[];
}

const ItemsPerPage = 50;

type CSVExportModel = CSVExportViewModel & {
  questionnaireId: string;
};

export const CSVExportFormDef = buildGroup<CSVExportModel>()({
  reExportRecords: false,
  endDateTime: "",
  startDateTime: "",
  questionnaireId: "",
});

export function Queue({ queues }: { queues: QueueViewModel[] }) {
  const { enqueueSnackbar } = useSnackbar();
  const { queueId } = useParams<{ queueId: string }>();
  const currentQueue = queues.find((q) => q.id === queueId)!;
  const classes = useStyles();
  const queueClient = useApiClient(QueueClient);
  const personsClient = useApiClient(PersonsClient);
  const questionClient = useApiClient(QuestionnaireClient);
  const adminQuestionClient = useApiClient(QuestionnairesClient);
  const teamClient = useApiClient(TeamClient);
  //GLOBAL STATE
  const { state } = useContext(Context);
  const userOffset =  new Date().getTimezoneOffset()*60*1000;
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [onFirstPage, setOnFirstPage] = useState(true);
  const [csvLoading, setCsvLoading] = useState(false);
  const [queue, setQueue] = useState<QueueViewModel>();
  const [queueWorkItems, setQueueWorkItems] = useState<any>();
  const [teams, setTeams] = useState<TeamViewModel[]>();
  const [queueWorkItem, setQueueWorkItem] = useState<QueueWorkItemViewModel>();
  const [queueWorkItemStatus, setQueueWorkItemStatus] =
    useState<QueueWorkItemStatus>();
  const [metrics, setMetrics] = useState<any>([]);
  const [searchFilters, _setSearchFilters] = useState<SearchFilter>({
    queryText: "",
    queueId,
    status: [QueueWorkItemStatus.CheckIns],
    after: startOfToday(),
    before: endOfToday(),
    excluded: [],
  });

  function setSearchFilters(newFilter: SearchFilter) {
    _setSearchFilters((sf) => (deepEqual(sf, newFilter) ? sf : newFilter));
  }
  const [defaultExcludes, setDefaultExcludes] = useState<string[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [isDatePickerExpanded, setIsDatePickerExpanded] =
    useState<boolean>(false);
  const [isUpdateFromDatePicker, setIsUpdateFromDatePicker] =
    useState<boolean>(false);
  const [hasDateChanged, setHasDateChanged] = useState<boolean>(false);
  const [queueWorkItemStatusChanged, setQueueWorkItemStatusChanged] =
    useState<boolean>(false);
  const [isInitialApiCalled, setIsInitialApiCalled] = useState<boolean>(false);
  const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);
  const [drawerTitle, setDrawerTitle] = useState<string>();
  const [token, setToken] = useState<string | undefined>(state.user?.token);
  const [queueWorkItemLoading, setQueueWorkItemLoading] =
    useState<boolean>(true);
  const [pass, setPass] = useState<number>(0)
  const [fail, setFail] = useState<number>(0)
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const [queueChanged, setQueueChanged] = useState<boolean>();
  const [assignToQueueMode, setAssignToQueueMode] = useState<boolean>(false);
  const [excludeFilter, setExcludeFilter] = useState<any>();
  const [referralFormInitiated, setReferralFormInitiated] =
    useState<boolean>(false);
  const [referralFormFinalised, setReferralFormFinalised] =
    useState<boolean>(false);
  const [selectedExcludes, setSelectedExcludes] = useState<any>();
  const [metricCatSelect, setMetricCatSelect] = useState(false);

  const sideDrawer = useRef<any>(null);
  const toggleSideDrawer = sideDrawer?.current;

  const [questionnaireResponseId, setQuestionnaireResponseId] =
    useState<string>();
  const questionnaireSideDrawer = useRef<any>(null);
  const toggleQuestionnaireSideDrawer = questionnaireSideDrawer?.current;

  const [updateQueueData, setUpdateQueueData] = useState(false);
  const [updateQueueAssignemnt, setUpdateQueueAssignemnt] = useState(false);
  const [srQWI, setsrQWI] = useState<any>();
  const [srUAQWI, setsrUAQWI] = useState<any>();
  const [updateMetricsFromApi, setUpdateMetricsFromApi] = useState(false);

  //const [personForm] = useState(PersonDetailsFormDef);
  const [editPerson, setEditPerson] = useState(false);
  const [noteMode, _setNoteMode] = useState<boolean>(false);
  const [csvExportMode, setCSVExportMode] = useState<boolean>(false);

 // const [form] = useState(CSVExportFormDef);
  //const fields = form.fields;

  //const startDateFieldValue = useControlValue(fields.startDateTime);
  //const endDateFieldValue = useControlValue(fields.endDateTime);

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: loadMore,
    disabled: false,
    rootMargin: "0px 0px 400px 0px",
  });

  function startEditingNote() {
    _setNoteMode(true);
    noteKey++;
  }

  const [treeData, setTreeData] = useState<
    { name: string; value: string; label: string }[]
  >([]);

  //const [outcomeChoices, setOutcomes];

  useSignalR("/api/hub/queue", state.user?.token, (conn: HubConnection) => {
    conn.on("queueitem-updated", (workItem: any) => {
      setsrQWI(workItem);
      setUpdateQueueData(true);
      setUpdateQueueAssignemnt(true);
    });
    conn.on("queue-updated", (queueId: string) => {
      if (currentQueue.id == queueId) {
        setUpdateQueueAssignemnt(true);
      }
    });
    conn.on("referral-added", () => {
      if (currentQueue.name == "Hotline") {
        setUpdateQueueAssignemnt(true);
      }
    });
  });

  let MetricCategories = AllQueueWorkItems.map((wi) => QueueWorkItemStatus[wi]);
  let QueueWorkItemStatuses = AllQueueWorkItems;

  //const treeData: any[] = [];

  async function formFinalised() {
    var d = await questionClient.finaliseQuestionnaireByAdmin(
      queueWorkItem!.id,
      questionnaireResponseId!
    );
    setReferralFormFinalised(true);
    //setReferralPerson(undefined);
    //@ts-ignore
    //setDrawerTitle(`${MetricCategories[d.queueWorkItem!.status]} Referral`);
    //onQueueWorkItemSelected(queueWorkItem);
    //setQueueWorkItemLoading(true)
    showWorkItem(d.queueWorkItem!);
  }

  function showWorkItem(qwi: QueueWorkItemViewModel) {
    setQueueWorkItem(qwi);
    setShowQuestionnaire(false);
    toggleQuestionnaireSideDrawer.closeDrawer();
    toggleSideDrawer.openDrawer();
    setQuestionnaireResponseId(undefined);
  }

  async function registerFormUser(user: LoginUserViewModel) {
    try {
      const newPerson = {
        ...user,
        kind: PersonKind.Visitor,
      };
      const newWorkItem = await queueClient.createNewQueueWorkItem(
        currentQueue.id,
        newPerson
      );
      setQueueWorkItem(newWorkItem);
      if (currentQueue.launchForm) {
        setQuestionnaireResponseId(
          newWorkItem.workItem.questionnaireResponseId
        );
      } else {
        showWorkItem(newWorkItem);
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Failed to register person.", { variant: "error" });
    }
  }

  async function getQuestionnaireIdForQueue() {
    try {
      //const qId = await adminQuestionClient.getQuestionnaireForPersonKind(kind);
      const qId = await queueClient.getQuestionnaireByQueueId(currentQueue.id);
      const qrId = await questionClient.createNewResponseForQueueWorkItem(
        qId,
        queueWorkItem!.workItem.person.id!,
        queueWorkItem!.id
      );
      setQuestionnaireResponseId(qrId.id);
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Failed to register person.", { variant: "error" });
    }
  }

  async function doItemQuery(offset?: number) {
    let status = [QueueWorkItemStatus.CheckIns];
    if (currentQueue.id === "14616BE4-AE65-449E-B76B-E5988EE896F8" )
    status = [QueueWorkItemStatus.Survey];
    else if (currentQueue.id === "D5A9C364-A642-4CDB-9908-87DA3E6AFCB6")  
    status =  [QueueWorkItemStatus.Survey];
    const queueData = await queueClient.getQueueWorkItems(
      queueId,
      offset ?? 0,
      ItemsPerPage,
      {
        after: searchFilters.after as any,
        before: searchFilters.before as any,
        query: searchFilters.queryText,
        queueWorkItemStatuses: status,
        outcomes: searchFilters.outcomes,
        departments: searchFilters.departments,
      }
    );
    setHasMore((queueData.queueWorkItems?.length ?? 0) === ItemsPerPage);
    setOnFirstPage((offset ?? 0) === 0);

    return queueData;
  }

  const loadQueueData = () => {
    setLoading(true);
    let apiCalls = [];

    apiCalls.push(doItemQuery());

    apiCalls.push(queueClient.getQueueOutcomesByQueueId(queueId));

    if (hasDateChanged || queueWorkItemStatusChanged || !isInitialApiCalled) {
      apiCalls.push(
        api.getQueueMetrics(
          queueId,
          searchFilters.before,
          searchFilters.after,
          searchFilters.queryText,
          searchFilters.outcomes,
          searchFilters.departments,
          token
        )
      );
    }

    apiCalls.push(teamClient.getAllTeams());

    Promise.all(apiCalls)
      .then((res) => {

        const [queue, outcomes, metrics, teams] = res;
        setQueue(queue);
        metrics && setMetrics(metrics);
        setHasDateChanged(false);
        setQueueWorkItemStatusChanged(false);
        setQueueWorkItemStatus(undefined);
        if (teams) {
          setTeams(
            teams.map((t: TeamViewModel) => ({
              name: t.name,
              label: t.name,
              value: t.id,
            }))
          );
        }

        if (outcomes) {
          var outcomesArray = outcomes.map((o: QueueChoiceVm) => ({
            name: o.name,
            label: o.name,
            value: o.id,
            children: o.children
              ? o.children.map((a: QueueChoiceVm) => ({
                  name: a.name,
                  label: a.name,
                  value: a.id,
                  children: a.children
                    ? a.children.map((a: QueueChoiceVm) => ({
                        name: a.name,
                        label: a.name,
                        value: a.id,
                        children: a.children,
                      }))
                    : null,
                }))
              : null,
          }))
          setTreeData(outcomesArray);
          setExcludeFilter(outcomesArray);
         
          var defaultExlude: string[] = [];
          var temp: QueueChoiceVm[] = [];
          outcomes.forEach((x: QueueChoiceVm) => {
            if (x.children !== null) {
            x.children!.forEach((y:QueueChoiceVm) => {
              temp.push(y)
            });
          }
          });
          
          var hobFG = temp!.find((x: QueueChoiceVm) => x.outcomeIdentifier == OutcomeIdentifier.Hobart_Front_Gate)
          var portFG = temp!.find((x: QueueChoiceVm) => x.outcomeIdentifier == OutcomeIdentifier.Port_Pirie_Front_Gate)
          if (hobFG)  defaultExlude.push(hobFG.id)
          if (portFG) defaultExlude.push(portFG.id) 
          console.log(defaultExlude)
          setDefaultExcludes([...defaultExlude])
          setSelectedExcludes({...defaultExlude})
        }
        if (searchFilters.outcomes === undefined || searchFilters.outcomes.length === 0){
           setPass(queue?.queueWorkItems.filter((q: any) => q.outcomes.filter((o: any) => o.name === "Pass").length > 0).length)
           setFail(queue?.queueWorkItems.filter((q: any) => q.outcomes.filter((o: any) => o.name === "Fail").length > 0).length)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getQueueDataWithoutRestriction = () => {
    let apiCalls = [];
    apiCalls.push(doItemQuery());

    apiCalls.push(
      api.getQueueMetrics(
        currentQueue!.id,
        searchFilters.before,
        searchFilters.after,
        searchFilters.queryText,
        searchFilters.outcomes,
        searchFilters.departments,
        token
      )
    );

    Promise.all(apiCalls)
      .then((res) => {
        const [queue, metrics] = res;
        setQueue(queue);
        metrics && setMetrics(metrics);
      })
      .catch((err) => {
        console.log(err);
      });

    if (referralFormFinalised) setReferralFormFinalised(false);
  };

  const onWorkItemAssigned = (queueWorkItem: any) => {
    setQueueWorkItemStatusChanged(true);
    setQueueWorkItemLoading(true);
    if (
      queueWorkItem.personAssignedTo &&
      queueWorkItem.status == QueueWorkItemStatus.CheckIns
    ) {
      //call unassign api

      api
        .UnAssignWorkItem(currentQueue!.id, queueWorkItem.workItem.id, token)
        .then((d) => {
          setQueueWorkItem(d);
        })
        .catch((err) => console.log(err));
    } else {
      //call assign api
      api
        .AssignWorkItemToMe(currentQueue!.id, queueWorkItem.workItem.id, token)
        .then((d) => {
          setQueueWorkItem(d);
        })
        .catch((err) => console.log(err));
    }
  };
  const onWorkItemNoteAdded = (
    queueWorkItemNote: string,
    globalNote: boolean
  ) => {
    setQueueWorkItemLoading(true);
    if (
      queueWorkItemStatusChanged &&
      queueWorkItem?.workItem.id &&
      queueWorkItemStatus == QueueWorkItemStatus.OnSite
    ) {
      setQueueWorkItemStatusChanged(true);
      queueClient
        .updateQueueWorkItemStatus(
          currentQueue!.id,
          queueWorkItem!.workItem.id,
          { note: queueWorkItemNote, status: queueWorkItemStatus }
        )
        .then((d) => {
          setQueueWorkItem(d);
        })
        .catch((err) => {
          setQueueWorkItemLoading(false);
          setQueueWorkItemStatus(queueWorkItem!.status);
          enqueueSnackbar("Failed to update referral", { variant: "error" });
        });
    } else {
      if (queueWorkItem?.workItem.id) {
        api
          .SaveWorkItemNote(
            currentQueue!.id,
            queueWorkItem!.workItem.id,
            queueWorkItemNote,
            globalNote,
            token
          )
          .then((qwi) => {
            setQueueWorkItem(qwi);
          })
          .catch((err) => console.log(err));
      }
    }
  };
  const onDatePickerChangeHandler = (range: any) => {
    if (
      searchFilters &&
      (searchFilters?.after !== range[0] || searchFilters?.before !== range[1])
    ) {
      setLoading(true);
      setIsUpdateFromDatePicker(true);
      setHasDateChanged(true);
      setSearchFilters({
        ...searchFilters,
        before: range[1],
        after: range[0],
      });
    }
  };
  const handleStatusSelect = (newFormats: QueueWorkItemStatus[]) => {
    //setLoading(true);
    setSearchFilters({
      ...searchFilters,
      status: newFormats,
    });
    setIsFilterChanged(true);
    setMetricCatSelect(true);
  };

  const WorkItemIcon = (state: number): JSX.Element | undefined => {
    switch (state) {
      case QueueWorkItemStatus.CheckIns:
        return <CheckCircleOutlineIcon />; //return <ErrorOutlineOutlinedIcon />;
      // case QueueWorkItemStatus.InProgress:
      // 	return <PlayCircleOutlineIcon />;
      case QueueWorkItemStatus.OnSite:
        return <DescriptionOutlinedIcon />;
      // case QueueWorkItemStatus.Closed:
      // 	return <CheckCircleOutlineIcon />;
    }
  };

  function addNote(noteTextField: string, globalNote: boolean) {
    onWorkItemNoteAdded(noteTextField, globalNote);
    handleClose();
  }

  const handleClose = () => {
    _setNoteMode(false);
    setQueueWorkItemStatus(undefined);
  };

  const handleAssignToQueueClose = () => {
    setAssignToQueueMode(false);
  };

  const handleAssignAndCloseQueueClose = () => {
    setQueueWorkItemStatus(QueueWorkItemStatus.CheckIns);
    setAssignToQueueMode(false);
  };

  const handleStatusChange = (
    event: React.MouseEvent<HTMLElement>,
    newStatuses: QueueWorkItemStatus[]
  ) => {
    setLoading(true);
    setSearchFilters({
      ...searchFilters,
      status: newStatuses,
    });
    setIsFilterChanged(true);
  };

  const teamDataMapper = (roles: any[]) => {
    return roles.map((x: any) => {
      return {
        value: x.id,
        label: x.name,
      };
    });
  };

  const traceContacts = async (offset?: number) => {
    const temp: string[] = Object.values(selectedExcludes)

    const queueData = await queueClient.getTraceContacts(
      queueId,
      offset ?? 0,
      ItemsPerPage,
      {
        after: searchFilters.after as any,
        before: searchFilters.before as any,
        query: searchFilters.queryText,
        queueWorkItemStatuses: searchFilters.status,
        outcomes: searchFilters.outcomes,
        departments: searchFilters.departments,
        excludes: temp,
      }
    );
    setHasMore((queueData.queueWorkItems?.length ?? 0) === ItemsPerPage);
    setOnFirstPage((offset ?? 0) === 0);

    return queueData;
  };

  const contactTracer = () => {
    setLoading(true);
    let apiCalls = [];

    apiCalls.push(traceContacts());

    apiCalls.push(queueClient.getQueueOutcomesByQueueId(queueId));

    if (hasDateChanged || queueWorkItemStatusChanged || !isInitialApiCalled) {
      apiCalls.push(
        api.getQueueMetrics(
          queueId,
          searchFilters.before,
          searchFilters.after,
          searchFilters.queryText,
          searchFilters.outcomes,
          searchFilters.departments,
          token
        )
      );
    }

    Promise.all(apiCalls)
      .then((res) => {
        const [queue, outcomes, metrics] = res;
        setQueue(queue);
        let m = { totals: [{ count: queue.queueWorkItems.length, status: 0 }] };
        metrics && setMetrics(m);
        setHasDateChanged(false);
        setQueueWorkItemStatusChanged(false);
        setQueueWorkItemStatus(undefined);

        if (outcomes) {
          var defaultExlude: string[] = [];
          var outcomesArray = outcomes.map((o: QueueChoiceVm) => ({
            name: o.name,
            label: o.name,
            value: o.id,
            children: o.children
              ? o.children.map((a: QueueChoiceVm) => ({
                  name: a.name,
                  label: a.name,
                  value: a.id,
                  children: a.children
                    ? a.children.map((a: QueueChoiceVm) => ({
                        name: a.name,
                        label: a.name,
                        value: a.id,
                        children: a.children,
                      }))
                    : null,
                }))
              : null,
          }))
          setTreeData(outcomesArray);
          setExcludeFilter(outcomesArray);
        
          var temp: QueueChoiceVm[] = []
          outcomes.forEach((x: QueueChoiceVm) => {
            x.children!.forEach((y:QueueChoiceVm) => {
              temp.push(y)
            });
           
          });
          var hobFG = temp!.find((x: QueueChoiceVm) => x.outcomeIdentifier == OutcomeIdentifier.Hobart_Front_Gate)
          var portFG = temp!.find((x: QueueChoiceVm) => x.outcomeIdentifier == OutcomeIdentifier.Port_Pirie_Front_Gate)
          if (hobFG)  defaultExlude.push(hobFG.id)
          if (portFG) defaultExlude.push(portFG.id) 
          setDefaultExcludes(defaultExlude)
          setSelectedExcludes({...defaultExlude})
        }
        if (searchFilters.outcomes === undefined || searchFilters.outcomes.length === 0){
          setPass(queue?.queueWorkItems.filter((q: any) => q.outcomes.filter((o: any) => o.name === "Pass").length > 0).length)
          setFail(queue?.queueWorkItems.filter((q: any) => q.outcomes.filter((o: any) => o.name === "Fail").length > 0).length)
       }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (state.user?.token) {
      setToken(state.user?.token);
    }
  }, []);
  useEffect(() => {
    if (currentQueue) {
      setQueueChanged(true);
      setSearchFilters({
        ...searchFilters,
        status: [QueueWorkItemStatus.CheckIns],
        after: searchFilters?.after ?? startOfToday(),
        before: searchFilters?.before ?? endOfToday(),
        outcomes: undefined,
      });
      setUpdateMetricsFromApi(true);
    }
    if (csvExportMode) {
      setCSVExportMode(false);
     // fields.startDateTime.setValue("");
     // fields.endDateTime.setValue("");
    }
  }, [currentQueue]);
  useEffect(() => {
    loadQueueData();
  }, [searchFilters]);
  useEffect(() => {
    if (!csvExportMode) loadQueueData();
  }, [csvExportMode]);
  useEffect(() => {
    setSearchFilters({
      queueId,
      queryText: "",
      status: [QueueWorkItemStatus.CheckIns],
      after: startOfToday(),
      outcomes: [],
      before: endOfToday(),
    });
  }, [queueId]);
  useEffect(() => {
    if (queue) {
      setQueueWorkItems(queue.queueWorkItems);
    }
  }, [queue]);
  useEffect(() => {
    if (queueWorkItems && queueWorkItems.length >= 0) {
      setLoading(false);
    }
  }, [queueWorkItems]);
  useEffect(() => {
    if (queueWorkItem) {
      setQueueWorkItemLoading(false);
    }
  }, [queueWorkItem]);

  useEffect(() => {
    switch (queueWorkItemStatus) {
      case QueueWorkItemStatus.OnSite:
        startEditingNote();
        setQueueWorkItemStatusChanged(true);
        break;
      // case QueueWorkItemStatus.Closed:
      // 	setQueueWorkItemStatusChanged(true);
      // 	if ( queueWorkItem?.workItem.id){
      // 	queueClient
      // 		.updateQueueWorkItemStatus(
      // 			currentQueue!.id,
      // 			queueWorkItem!.workItem.id,
      // 			{ note: "", status: queueWorkItemStatus }
      // 		)
      // 		.then((d) => {
      // 			setQueueWorkItem(d);
      // 			setQueueWorkItemStatus(undefined);
      // 		})
      // 		.catch((err) => {
      // 			setQueueWorkItemLoading(false);
      // 			setQueueWorkItemStatus(queueWorkItem!.status);
      // 			enqueueSnackbar("Failed to update referral", { variant: "error" });
      // 		});
      // 	}

      // 	break;
      // case QueueWorkItemStatus.InProgress:
      // 	onWorkItemAssigned(queueWorkItem);
      // 	setQueueWorkItemStatus(undefined);
      // 	break;
      case QueueWorkItemStatus.CheckIns:
        onWorkItemAssigned(queueWorkItem);
        setQueueWorkItemStatus(undefined);
        break;
    }
  }, [queueWorkItemStatus]);
  useEffect(() => {
    if (updateMetricsFromApi) {
      api
        .getQueueMetrics(
          currentQueue!.id,
          searchFilters.before,
          searchFilters.after,
          searchFilters.queryText,
          searchFilters.outcomes,
          searchFilters.departments,
          token
        )
        .then((m) => setMetrics(m));
      setUpdateMetricsFromApi(false);
    }
  }, [updateMetricsFromApi]);
  useEffect(() => {
    if (updateQueueData) {
      if (srQWI) {
        //let qwi = queue.queueWorkItems.find((q: any) => q.id == srQWI.id);

        // setQueueWorkItems((qwi: any) =>
        //   qwi.map((q: any) =>
        //     q.id == srQWI.id
        //       ? {
        //           ...q,
        //           personAssignedTo: srQWI.personAssignedTo,
        //           status: srQWI.status,
        //         }
        //       : q
        //   )
        // );
        if (queueWorkItem && queueWorkItem.id == srQWI.id) {
          queueWorkItem.status = srQWI.status;
          queueWorkItem.personAssignedTo = srQWI.personAssignedTo;
        }
      }
      setUpdateQueueData(false);
    }
    if (updateQueueAssignemnt) {
      getQueueDataWithoutRestriction();
      setUpdateQueueAssignemnt(false);
      //setUpdateMetricsFromApi(true);
    }
  }, [updateQueueData, updateQueueAssignemnt]);
  useEffect(() => {
    if (referralFormFinalised) {
      getQueueDataWithoutRestriction();
    }
  }, [referralFormFinalised]);
  useEffect(() => {
    setMetricCatSelect(false);
  }, [metricCatSelect]);
  
  return (
    <>
      <Drawer
        ref={sideDrawer}
        anchor={"right"}
        onClose={() => {
          toggleSideDrawer.closeDrawer();
          setEditPerson(false);
        }}
        title={drawerTitle}
      >
        {queueWorkItemLoading ? (
          <>
            <Skeleton variant="rect" width={"100%"} height={250} />
            <Skeleton
              variant="rect"
              style={{ marginTop: "10px" }}
              width={"100%"}
              height={50}
            />
            <Skeleton
              variant="rect"
              style={{ marginTop: "10px" }}
              width={"100%"}
              height={50}
            />
            <Skeleton
              variant="rect"
              style={{ marginTop: "10px" }}
              width={"100%"}
              height={50}
            />
            <SkeletonTimeLine />
          </>
        ) : (
          <>
            <OutcomeCard width={"100%"}>
              <OutcomeHeaderListItem
                variant={MetricCategories[queueWorkItem!.status!]}
                text={"Contact Check In"}
                icon={WorkItemIcon(queueWorkItem!.status!)}
              />
              <AppBar elevation={2}>
                <div style={{ display: "flex", margin: "auto" }}>
                  {/* <AppBarDivider flexItem orientation="vertical" /> */}
                  {/* <AppBarIconButton
										title={`${
											queueWorkItem?.personAssignedTo
												? "UNASSIGN"
												: "ASSIGN TO ME"
										}`}
										onClick={() => onWorkItemAssigned(queueWorkItem)}
										aria-label="Add Team"
										//disabled={!toggleSideDrawer || !hasModifyTeamMemberAccess }
									>
										{queueWorkItem?.personAssignedTo ? (
											<UserMinusSolid />
										) : (
											<UserPlusSolid />
										)}
									</AppBarIconButton> */}
                  {/* <AppBarDivider flexItem orientation="vertical" />
									<AppBarIconButton
										title={`${
											queueWorkItem?.status == QueueWorkItemStatus.OnSite
												? "RESUME"
												: "PUT ON HOLD"
										}`}
										aria-label="Add Team"
										onClick={() => {
											setQueueWorkItemStatus(
												queueWorkItem?.status !== QueueWorkItemStatus.OnSite
													? QueueWorkItemStatus.OnSite
													: queueWorkItem?.personAssignedTo
													? QueueWorkItemStatus.CheckIns
													: QueueWorkItemStatus.CheckIns
											);
										}}
									>
										{queueWorkItem?.status == QueueWorkItemStatus.OnSite ? (
											<PlayCircleOutlineIcon />
										) : (
											<PauseCircleOutlineIcon />
										)}
									</AppBarIconButton>
									<AppBarDivider flexItem orientation="vertical" />
									<AppBarIconButton
										title={"place holder"}
										aria-label="Add Team"
										onClick={() => {
											
										}}
									>
										{queueWorkItem?.status == QueueWorkItemStatus.OnSite ? (
											<CheckCircleOutlineIcon
												onClick={() => {
													// if (queueWorkItem?.personAssignedTo) {
													// 	// onWorkItemAssigned(queueWorkItem);
													// 	// setQueueWorkItemStatus(
													// 	// 	QueueWorkItemStatus.InProgress
													// 	);
													// } else {
													// 	//onWorkItemAssigned(queueWorkItem);
													// 	setQueueWorkItemStatus(QueueWorkItemStatus.CheckIns);
													// }
												}}
											/>
										) : (
											<CheckCircleOutlineIcon
												onClick={() => {
													//setNoteMode(true);
													//setQueueWorkItemStatusChanged(true);
													//setQueueWorkItemStatus(QueueWorkItemStatus.Closed);
												}}
											/>
										)}
									</AppBarIconButton>

									<AppBarDivider flexItem orientation="vertical" />
									<AppBarIconButton
										title={"ADD A NOTE"}
										aria-label="Add Team"
										onClick={() => {
											setQueueWorkItemStatus(undefined);
											startEditingNote();
										}}
										//disabled={!toggleSideDrawer || !hasModifyTeamMemberAccess }
									>
										<CommentIcon />
									</AppBarIconButton>
									<AppBarDivider flexItem orientation="vertical" />
									<AppBarIconButton
										title={"Assign to another queue"}
										aria-label="Assign To Queue"
										//disabled={!toggleSideDrawer || !hasModifyTeamMemberAccess }
										onClick={() => setAssignToQueueMode(true)}
									>
										<AddCircleOutlineIcon />
									</AppBarIconButton>

									<AppBarDivider flexItem orientation="vertical" />
									{currentQueue.canCreateForm && !currentQueue.launchForm && (
										<>
											<AppBarIconButton
												title={
													currentQueue.formCreateHelpText ??
													"Create Risk Assessment"
												}
												aria-label={
													currentQueue.formCreateHelpText ??
													"Create Risk Assessment"
												}
												onClick={() => {
													getQuestionnaireIdForQueue().then((d) => {
														setShowQuestionnaire(true);
													});
												}}
											>
												<PostAddIcon />
											</AppBarIconButton>
											<AppBarDivider flexItem orientation="vertical" />
										</>
									)}

								 */}
                </div>
              </AppBar>
              <OutcomeDetailsListItem
                text={queueWorkItem?.workItem.person ? `Name: ${queueWorkItem?.workItem.person.firstName} ${queueWorkItem?.workItem.person.lastName}` : `Name: `}
                icon={
                  <Tooltip title={"Contact Person"}>
                    <UserSolid />
                  </Tooltip>
                }
                // editor={editor(
                //   6,
                //   <FTextField
                //     fullWidth
                //     state={personForm.fields.firstName}
                //     label="First Name"
                //   />,
                //   <FTextField
                //     fullWidth
                //     state={personForm.fields.lastName}
                //     label="Last Name"
                //   />
                // )}
                divider={true}
              />
              <OutcomeDetailsListItem
                text={`Contact Number: ${queueWorkItem?.workItem.person.contactNumber}`}
                icon={
                  <Tooltip title={"Contact Number"}>
                    <PhoneIcon />
                  </Tooltip>
                }
                // editor={editor(
                //   12,
                //   <FTextField
                //     state={personForm.fields.contactNumber}
                //     label="Contact Number"
                //     fullWidth
                //   />
                // )}
                divider={true}
              />
              {/* <OutcomeDetailsListItem
								text={queueWorkItem?.workItem.person.shortDateOfBirth}
								icon={
									<Tooltip title={"Date of Birth"}>
										<EventIcon />
									</Tooltip>
								}
								divider={true}
								editor={editor(
									12,
									<FDateField
										state={personForm.fields.dateOfBirth}
										type="date"
										label="Date of Birth"
										fullWidth
									/>
								)}
							/> */}
              {/* <OutcomeDetailsListItem
                text={queueWorkItem?.workItem.person.sex}
                icon={<WcIcon />}
                divider={true}
              />
              <OutcomeDetailsListItem
                text={queueWorkItem?.workItem.person.emailAddress}
                icon={<EmailIcon />}
                divider={true}
              /> */}
              <OutcomeDetailsListItem
                text={`Department: ${queueWorkItem?.workItem.person.department?.name}`}
                icon={
                  <Tooltip title={"Department"}>
                    <RolesSoild />
                  </Tooltip>
                }
                divider={true}
              />
              {queueWorkItem!.outcomes && queueWorkItem!.outcomes.length > 0 && (
                <OutcomeDetailsListItem
                  icon={
                    <Tooltip title={"Location"}>
                      <HomeIcon />
                    </Tooltip>
                  }
                  divider={true}
                  text={`Location: ${queueWorkItem!.outcomes
                    .map((o: QueueOutcomeViewModel) => o.name)
                    .reverse()
                    .join(" > ")}`}
                />
              )}

              {queueWorkItem!.workItem.checkIn ? (
                <OutcomeDetailsListItem
                  text={`Check in time: ${format(
                    new Date(Date.parse(queueWorkItem!.workItem.checkIn.checkInTime)- userOffset),
                    "dd-MM-yyyy HH:mm:ss "
                  )}`}
                  icon={
                    <Tooltip title={"Check In Time"}>
                      <AccessTimeIcon />
                    </Tooltip>
                  }
                  divider={true}
                  editor={editor(
                    12
                    // <FTextField
                    // 	state={personForm.fields.generalPractitionerName}
                    // 	label={"General Practitioner"}
                    // 	fullWidth
                    // />
                  )}
                />
              ) : (
                <OutcomeDetailsListItem
                text={`Completed at: ${format(
                  new Date(Date.parse(queueWorkItem!.workItem.createdAt!)- userOffset),
                  "dd-MM-yyyy HH:mm:ss "
                )}`}
                icon={
                  <Tooltip title={"Completed at"}>
                    <AccessTimeIcon />
                  </Tooltip>
                }
                divider={true}
                editor={editor(
                  12
                  // <FTextField
                  // 	state={personForm.fields.generalPractitionerName}
                  // 	label={"General Practitioner"}
                  // 	fullWidth
                  // />
                )}
              />

              )}
              {queueWorkItem!.workItem.checkIn && (
                <OutcomeDetailsListItem
                  text={`Check out time: ${
                    queueWorkItem!.workItem.checkIn.checkOutTime
                      ? format(
                        new Date(Date.parse(queueWorkItem!.workItem.checkIn.checkOutTime)- userOffset),
                        "dd-MM-yyyy HH:mm:ss "
                      )
                      : "Still Checked In"
                  }`}
                  icon={
                    <Tooltip title={"Check Out Time"}>
                      <AccessTimeIcon />
                    </Tooltip>
                  }
                  divider={true}
                  editor={editor(
                    12
                    // <FTextField
                    // 	state={personForm.fields.generalPractitionerName}
                    // 	label={"General Practitioner"}
                    // 	fullWidth
                    // />
                  )}
                />
              )}
              {editPerson ? (
                <QuestionItemYesNoButtons
                  hideCheckbox={true}
                  buttons={
                    [
                      { id: "1", text: "Save" },
                      { id: "2", text: "Cancel" },
                    ] as QuestionChoice[]
                  }
                  onSelected={(selected: QuestionChoice) => {
                    if (selected.text === "Save") {
                      savePerson();
                    } else {
                      setEditPerson(false);
                    }
                  }}
                />
              ) : null
              // <QuestionItemYesNoButtons
              // 	hideCheckbox={true}
              //   buttons={
              //     [
              //       { id: "1", text: "Edit Referral Information" },
              //     ] as QuestionChoice[]
              //   }
              // 					onSelected={(selected: QuestionChoice) => {
              // 						startEditingPerson();
              // 					}}
              ///>
              }
              {/*<Box m={2}>*/}
              {/*  {editPerson ? (*/}
              {/*    <>*/}
              {/*      <Button onClick={savePerson}>Save</Button>{" "}*/}
              {/*      <Button onClick={() => setEditPerson(false)}>Cancel</Button>*/}
              {/*    </>*/}
              {/*  ) : (*/}
              {/*    <Button onClick={startEditingPerson}>Edit</Button>*/}
              {/*  )}*/}
              {/*</Box>*/}
            </OutcomeCard>
            { currentQueue.queueIndex !== QueueIndex.Site_locator && (
            <QueueWorkItemTabs
							queueId={currentQueue!.id}
							queueWorkItem={queueWorkItem!}
							onEdit={(responseId) => {
								setQuestionnaireResponseId(responseId);
								setShowQuestionnaire(true);
							}}
						/>
            )}
          </>
        )}
      </Drawer>
      <QueueQuestionnaireDrawer
        questionnaireSideDrawer={questionnaireSideDrawer}
        show={showQuestionnaire}
        drawerTitle={drawerTitle!}
        formFinalised={formFinalised}
        questionnaireResponseId={questionnaireResponseId}
        registerFormUser={registerFormUser}
        allFormDetails={true} //changeed from checking of pheoc queue
        onClose={(event: any, reason: string) => {
          if (reason != "backdropClick" && reason != "escapeKeyDown") {
            setShowQuestionnaire(false);
            toggleQuestionnaireSideDrawer.closeDrawer();
            setQuestionnaireResponseId(undefined);
          } else {
          }
        }}
      />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={10}
        style={{ marginBottom: "10px" }}
      >
        {metrics.totals && currentQueue.queueIndex === QueueIndex.Site_locator &&
          MetricCategories.map((category: any, key: number) => (
            <Grid item key={key}>
              <SelectableStatisticCard
                disabled={loading}
                title={
                  <Typography variant={"body1"}>
                    {category || "Unknown"}
                  </Typography>
                }
                value={
                  <Typography variant={"h1"} className={category}>
                    {metrics?.totals
                      ?.filter((metric: any) => metric.status == key)
                      .map((m: any) => m.count)[0] || 0}
                  </Typography>
                }
                //onSelected={() => handleStatusSelect([key])}
              />
            </Grid>
          ))}
           
          {/* {currentQueue.queueIndex !== 0 && queue?.queueWorkItems &&
           
            <>
            <Grid item key={0}>
              <SelectableStatisticCard
                disabled={loading}
                title={
                  <Typography variant={"body1"} >
                    Pass
                  </Typography>
                }
                value={
                  <Typography variant={"h1"} className={'CheckIns'}>
                    {pass}
                  </Typography>
                }
               // onSelected={() => handleStatusSelect(["Pass"])}
              />
            </Grid>
             <Grid item key={1}>
             <SelectableStatisticCard
               disabled={loading}
               title={
                 <Typography variant={"body1"}>
                   Fail
                 </Typography>
               }
               value={
                 <Typography variant={"h1"} className={'OnSite'}>
                    {fail}
                 </Typography>
               }
               //onSelected={() => handleStatusSelect([key])}
             />
           </Grid>
           </>
} */}
      </Grid>
      <AppBar elevation={3} style={{ justifyContent: "space-around" }}>
        <>
          {!csvExportMode && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                 // marginLeft: "250px",
                }}
              >
                <SearchAppBar
                  searchFilters={searchFilters}
                  queryChanged={(q) =>
                    setSearchFilters({ ...searchFilters, queryText: q })
                  }
                />

                <AppBarDivider flexItem orientation="vertical" />

                <CalendarsDateRangePicker
                  key={queueId}
                  onOpen={() => setIsDatePickerExpanded(true)}
                  onClose={() => setIsDatePickerExpanded(false)}
                  disabled={loading}
                  isDatePickerExpanded={isDatePickerExpanded}
                  onRangeChange={onDatePickerChangeHandler}
                />
                <AppBarDivider flexItem orientation="vertical" />
              </div>
              { currentQueue.queueIndex === QueueIndex.Site_locator && (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Button
                  style={{ float: "right" }}
                  onClick={() => contactTracer()}
                  className={classes.button}
                  // disabled={
                  // 	startDateFieldValue == "" ||
                  // 	endDateFieldValue == "" ||
                  // 	csvLoading
                  // }
                >
                  Trace Contacts
                </Button>
                {/* <AppBarDivider flexItem orientation="vertical" />
							<ToggleButtonGroup
								size="large"
								value={searchFilters?.status}
								onChange={handleStatusChange}
								aria-label="text alignment"
							>
								{QueueWorkItemStatuses.map((t) => (
									<TooltipToggleButton
										key={t}
										title={MetricCategories[t]}
										value={t}
										className={MetricCategories[t]}
										disabled={loading}
									>
										{WorkItemIcon(t as QueueWorkItemStatus)}
									</TooltipToggleButton>
								))}
							</ToggleButtonGroup> */}
                <AppBarDivider flexItem orientation="vertical" />
                <Button
                  className={classes.button}
                  onClick={exportRecordsToCSV}
                  disabled={
                    (queue &&
                      (!queue.queueWorkItems ||
                        queue.queueWorkItems.length == 0)) ||
                    csvLoading
                  }
                >
                  Export CSV
                </Button>
              </div>
              )}
            </>
          )}

          {csvExportMode && (
            <>
              {/* <StyledFTextField
                state={fields.startDateTime}
                label={"Start DateTime"}
                type={"datetime-local"}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <StyledFTextField
                state={fields.endDateTime}
                label={"End DateTime"}
                type={"datetime-local"}
                InputLabelProps={{
                  shrink: true,
                }}
              /> */}
              {/*<StyledFTextField*/}
              {/*  state={fields.questionnaireId}*/}
              {/*  label={"Questionnaire ID"}*/}
              {/*/>*/}
              {/* <FCheckbox
                label={"Re-Export Records"}
                state={fields.reExportRecords}
              />
              <Button
                onClick={retrieveCSVExportRecords}
                disabled={
                  startDateFieldValue == "" ||
                  endDateFieldValue == "" ||
                  csvLoading
                }
              >
                Retrieve
              </Button>

              <Button
                onClick={exportRecordsToCSV}
                disabled={
                  (queue &&
                    (!queue.queueWorkItems ||
                      queue.queueWorkItems.length == 0)) ||
                  csvLoading
                }
              >
                Export
              </Button> */}
            </>
          )}

          {/* {currentQueue.canExportCSV && (
            <>
              <AppBarDivider flexItem orientation="vertical" />

              <FormControlLabel
                control={
                  <Switch
                    checked={csvExportMode}
                    onChange={(_, exportMode) => {
                      setCSVExportMode(exportMode);
                      if (exportMode && queue) {
                        setQueue({ ...queue, queueWorkItems: [] });
                      }
                    }}
                  />
                }
                label="CSV Export"
              />
            </>
          )} */}
        </>
      </AppBar>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        spacing={4}
        style={{ marginTop: "15px" }}
      >
        <Grid item xs={7}>
          {loading || csvLoading ? (
            loading ? (
              <SkeletonCard count={5} />
            ) : (
              <>
                <CircularProgress />
                CSV Exporting...
              </>
            )
          ) : queueWorkItems.length > 0 ? (
            renderCards()
          ) : (
            <SelectableCard
              style={{ marginBottom: "15px", display: "flex" }}
              icon={<InfoOutlinedIcon />}
              content={
                <>
                  <Typography variant={"h5"}>No Records Found</Typography>
                  <SelectableCardIconContentItem
                    style={{ marginTop: "10px" }}
                  />
                </>
              }
            />
          )}
        </Grid>
        <Grid item xs={5}>
          {!csvExportMode && treeData && treeData.length > 0 && (
            <>
            <Grid>
              <CbxTree
                generic
                notFoundContent="No Locations to select"
                nodes={treeData}
                multiSelect={true}
                header={currentQueue.queueIndex === QueueIndex.Site_locator ? "Location Filters" : "Result Filter"}
                onChecked={(checked: string[]) => {
                  setSearchFilters({ ...searchFilters, outcomes: checked });
                  setIsFilterChanged(true);

                  // setQueueWorkItems(
                  //   queue.queueWorkItems.filter((q: QueueWorkItemViewModel) => {
                  //     if (q.outcomes?.some((r) => checked.includes(r.id)) == true)
                  //       return q;l
                  //   })
                  // );
                }}
              />
            </Grid>
            {currentQueue.queueIndex === QueueIndex.Site_locator && defaultExcludes.length > 0 && (
            <Grid style={{paddingTop:"15px"}}>
            <CbxTree
                generic
                notFoundContent="No Locations to select"
                nodes={excludeFilter}
                accordion={true}
                multiSelect={true}
                checked={defaultExcludes}
                header={"Locations To Exclude On Trace Contacts"}
                onChecked={(checked: string[]) => {
                  setSelectedExcludes({...checked});
                  //setIsFilterChanged(true);

                  // setQueueWorkItems(
                  //   queue.queueWorkItems.filter((q: QueueWorkItemViewModel) => {
                  //     if (q.outcomes?.some((r) => checked.includes(r.id)) == true)
                  //       return q;l
                  //   })
                  // );
                }}
              />
            </Grid>
            )}
            </>
          )}
          {!csvExportMode && teams && teams.length > 0 && (
            <Grid style={{ marginTop: "15px" }}>
              <CbxTree
                generic
                notFoundContent="No Departments to select"
                nodes={teams?.sort((a, b) => a.name!.localeCompare(b.name!))}
                multiSelect={true}
                header={"Department Filters"}
                onChecked={(checked: string[]) => {
                  setSearchFilters({ ...searchFilters, departments: checked });
                  setIsFilterChanged(true);

                  // setQueueWorkItems(
                  //   queue.queueWorkItems.filter((q: QueueWorkItemViewModel) => {
                  //     if (q.outcomes?.some((r) => checked.includes(r.id)) == true)
                  //       return q;l
                  //   })
                  // );
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <AssignToQueueDialog
        open={assignToQueueMode}
        onClose={handleAssignToQueueClose}
        onAssignAndClose={handleAssignAndCloseQueueClose}
        currentQueueId={currentQueue?.id}
        workItemId={queueWorkItem?.workItem.id}
        queues={queues}
        currentQueueName={currentQueue.name}
      />
      <AddNoteDialog
        handleClose={handleClose}
        noteMode={noteMode}
        submitNote={addNote}
        key={noteKey}
      />
      <Dialog
        open={referralFormInitiated && !referralFormFinalised}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <ClosableDialogTitle
          id="customized-dialog-title"
          onClose={handleAssignToQueueClose}
        >
          Refferal is not finalised!
        </ClosableDialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Press 'Continue' to continue with Referral. Otherwise, press 'Close'
            to close Referral from. No Queue Referral Item will be created.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={handleClose} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  async function loadMore() {
    setLoadingMore(true);
    setHasMore(false);
    const queueData = await doItemQuery(queueWorkItems.length);
    setQueue({
      ...queueData,
      queueWorkItems: [...queueWorkItems, ...queueData.queueWorkItems!],
    });
    setLoadingMore(false);
  }

  function renderCards() {
    return (
      <>
        {queueWorkItems!.map((queueWorkItem: any) => (
          <SelectableCard
            key={queueWorkItem.id}
            onSelected={() => {
              setDrawerTitle(
                `${MetricCategories[queueWorkItem.status]} Referral`
              );
              //onQueueWorkItemSelected(queueWorkItem);
              //setQueueWorkItemLoading(true)
              setQueueWorkItem(queueWorkItem);
              toggleSideDrawer.openDrawer();
            }}
            style={{ marginBottom: "15px", display: "flex" }}
            icon={WorkItemIcon(currentQueue.queueIndex === QueueIndex.Site_locator ? queueWorkItem.status : queueWorkItem.status + 1)}
            severity={MetricCategories[queueWorkItem.status]}
            content={
              <>
                <Typography variant={"body1"}>
                  {queueWorkItem.workItem.person.firstName}{" "}
                  {queueWorkItem.workItem.person.lastName}
                </Typography>
                <SelectableCardIconContentItem
                  style={{ marginTop: "10px" }}
                  icon={<PhoneIcon />}
                  text={queueWorkItem.workItem.person.contactNumber}
                />
                <SelectableCardIconContentItem
                  style={{ marginTop: "5px" }}
                  icon={queueWorkItem.workItem.checkIn ? <HomeIcon />  : <DescriptionOutlinedIcon/>}
                  text={queueWorkItem!.outcomes
                    .map((o: QueueOutcomeViewModel) => o.name)
                    .reverse()
                    .join(" > ")}
                />
                <SelectableCardIconContentItem
                  style={{ marginTop: "5px" }}
                  icon={<RolesSoild />}
                  text={queueWorkItem.workItem.person.department.name}
                />
                {queueWorkItem.workItem.checkIn ? (
                <SelectableCardIconContentItem
                  style={{ marginTop: "5px" }}
                  icon={<EventIcon />}
                  text={
                    format(
                      new Date(Date.parse(queueWorkItem.workItem.checkIn.checkInTime)- userOffset),
                      "dd-MM-yyyy HH:mm:ss "
                    ) +
                    "  >  " +
                    (queueWorkItem.workItem.checkIn.checkOutTime
                      ? format(
                          new Date(Date.parse(queueWorkItem.workItem.checkIn.checkOutTime)- userOffset),
                          " dd-MM-yyyy HH:mm:ss"
                        )
                      : "Still Checked In")
                  }
                />
                ) : (
                  <SelectableCardIconContentItem
                text={`${format(
                  new Date(Date.parse(queueWorkItem!.workItem.createdAt!)- userOffset),
                  "dd-MM-yyyy HH:mm:ss "
                )}`}
                icon={
                  <Tooltip title={"Completed at"}>
                    <AccessTimeIcon />
                  </Tooltip>
                }
                
                />
                )}
                {queueWorkItem.personAssignedTo && (
                  <>
                    <br />
                    <PersonAvatar
                      initials={`${queueWorkItem.personAssignedTo.firstName
                        .toString()
                        .charAt(0)
                        .toUpperCase()}${queueWorkItem.personAssignedTo.lastName
                        .toString()
                        .charAt(0)
                        .toUpperCase()} `}
                      fullName={`${queueWorkItem.personAssignedTo.firstName} ${queueWorkItem.personAssignedTo.lastName}`}
                    />
                  </>
                )}
              </>
            }
          />
        ))}
        {(loadingMore || hasMore) && (
          <div ref={sentryRef}>
            <Box textAlign={"center"}>
              <CircularProgress />
            </Box>
          </div>
        )}
      </>
    );
  }

  function editor(xs: GridSize, ...fields: ReactElement[]) {
    if (editPerson) {
      return (
        <Grid container spacing={1}>
          {fields.map((f, i) => (
            <Grid item xs={xs} key={i}>
              {f}
            </Grid>
          ))}
        </Grid>
      );
    }
    return undefined;
  }

  function startEditingPerson() {
    const p = queueWorkItem!.workItem.person;
    // const d = parse(p.shortDateOfBirth!, "dd/MM/yyyy", new Date());
    // personForm.setValue({
    // 	...p,
    // 	dateOfBirth: format(d, "yyyy-MM-dd"),
    // 	addressFull: p.addressFull ?? {},
    // });
    // personForm.setTouched(false);
    // personForm.setDisabled(false);
    // setEditPerson(true);
  }

  async function savePerson() {
  //  personForm.setDisabled(true);
    //const _personData = personForm.toObject();
    // const personData = {
    // 	..._personData,
    // 	address: toFullAddressLine(_personData.addressFull),
    // };

    const existingPerson = queueWorkItem?.workItem.person;
    // await personsClient.editPerson(existingPerson!.id, personData);
    // const newPerson = {
    // 	...existingPerson,
    // 	...personData,
    //shortDateOfBirth: format(parseISO(personData.dateOfBirth), "dd/MM/yyyy"),
    //};
    // setQueueWorkItem((qwi: any) => ({
    // 	...qwi,
    // 	workItem: { ...qwi.workItem, person: newPerson },
    // }));

    // setQueueWorkItems((qwi: any) =>
    // 	qwi?.map((qw: any) =>
    // 		qw.id == queueWorkItem!.id
    // 			? { ...qw, workItem: { ...qw.workItem, person: newPerson } }
    // 			: qw
    // 	)
    // );

    // setEditPerson(false);
  }

  // async function retrieveCSVExportRecords() {
  //   setCsvLoading(true);
  //   const csvExportData = form.toObject();
  //   const csvQueueWorkItems =
  //     await adminQuestionClient.getCSVExportForWorkItemId(
  //       queueId,
  //       currentQueue.defaultExportQuestionnaireId!,
  //       csvExportData
  //     );

  //   setQueue(csvQueueWorkItems);
  //   setCsvLoading(false);
  // }

  async function exportRecordsToCSV() {

    setCsvLoading(true);
    if (queue && queue.queueWorkItems) {
      let ids = queue.queueWorkItems.map((x) => x.workItem!.id!);
      if (queue.queueWorkItems.length == 10) {
        const queueData = await queueClient.getQueueWorkItems(
          queueId,
          0,
          999999,
          {
            after: searchFilters.after as any,
            before: searchFilters.before as any,
            query: searchFilters.queryText,
            queueWorkItemStatuses: searchFilters.status,
            outcomes: searchFilters.outcomes,
            departments: searchFilters.departments,
          }
        );
        ids = queueData.queueWorkItems!.map((x) => x.workItem!.id!);
      }

      if (ids !== undefined && ids.length !== 0) {
        const result = await adminQuestionClient.getExportsForWorkItems(userOffset,ids);

        saveAs(
          result.data,
          `nyrstar_contacts_export${format(
            new Date(),
            "dd-MM-yyyy HH:mm "
          )}.csv`
        );
        setCSVExportMode(false);
      }
    }
    setCsvLoading(false);

  }
}

const AllQueueWorkItems = [
  QueueWorkItemStatus.CheckIns,
  QueueWorkItemStatus.OnSite,
];

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

export const ClosableDialogTitle = withStyles(styles)(
  (props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h5">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  }
);

function SearchAppBar({
  searchFilters,
  queryChanged,
}: {
  searchFilters: SearchFilter;
  queryChanged: (text: string) => void;
}) {
  const [query, setQuery] = useState(searchFilters.queryText);
  const debounced = useDebounce(query, 500);
  useEffect(() => {
    queryChanged(debounced);
  }, [debounced]);
  useEffect(() => {
    setQuery(searchFilters.queryText);
  }, [searchFilters.queryText]);
  return (
    <AppBarSearch value={query} onChange={(e) => setQuery(e.target.value)} />
  );
}

export function AddNoteDialog({
  handleClose,
  noteMode,
  submitNote,
}: {
  handleClose: () => void;
  noteMode: boolean;
  submitNote: (noteText: string, global: boolean) => void;
}) {
  const [noteTextField, setNoteTextField] = useState<string>("");
  const [globalNote, setGlobalNote] = useState(true);

  const handleNoteCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setGlobalNote(event.target.checked);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={noteMode}
    >
      <ClosableDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Referral Notes
      </ClosableDialogTitle>
      <DialogContent dividers>
        <ValidationTextField
          style={{ width: "500px", height: "400px" }}
          aria-label="minimum height"
          // error={!formErrors.firstName.valid}
          // helperText={formErrors.firstName.errorMessage}
          margin="normal"
          fullWidth
          id="notes"
          label="Notes"
          name="notes"
          autoFocus
          required
          onChange={(e: any) => {
            setNoteTextField(e.target.value);
          }}
          variant="outlined"
          multiline={true}
          rows={3}
        />
        <FormControlLabel
          label={"Global Note"}
          control={
            <Checkbox
              checked={globalNote}
              onChange={handleNoteCheckboxChange}
            />
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => submitNote(noteTextField || "", globalNote)}
          disabled={noteTextField == "" || !noteTextField}
        >
          Save Notes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

let noteKey = 0;
