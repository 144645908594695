import React, { useContext, useEffect, useState } from "react";
import {
  AllSettingsViewModel,
  SettingsClient,
  SettingsViewModel,
} from "../../../../common/client";
import { useApiClient } from "../../../../common/apiclients";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  Divider,
  lighten,
  ListItemIcon,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import { grey } from "@material-ui/core/colors";
import { getSiteLocations } from "../../../../data/api";
import { Context } from "../../../../state/Store";
import { QueueChoiceVm } from "../../../../common/client";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Simulate } from "react-dom/test-utils";
import { isAbsolute } from "path";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    textLines: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    textAccordion: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0),
      display: "block!important",
    },
    locationTextFields: {
      //display: "flex",
      justifyContent: "center",
      paddingLeft: theme.spacing(2),
      marginTop: "-10px",
      paddingTop: "10px",
      float: "right",
    },
    settingsTextFields: {
      //display: "flex",
      justifyContent: "center",
      paddingLeft: theme.spacing(1),
      marginTop: "-10px",
      float: "right",
    },
    paper: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      margin: theme.spacing(2),
    },
    saveIcon: {
      marginTop: "-45px",
      float: "right",
      color: theme.palette.primary.main,
      height: "50px!important",
      width: "50px!important",
    },
    saveIconDisabled: {
      marginTop: "-45px",
      float: "right",
      color: "grey",
      height: "50px!important",
      width: "50px!important",
      opacity: 0.5,
    },
    header: {
      fontSize: "1.5rem",
      fontWeight: "bold",
    },

    accordion: {
      display: "block!important",
    },
  })
);

const Settings = () => {
  const { state, dispatch } = useContext(Context);
  const classes = useStyles();
  const settingsClient = useApiClient(SettingsClient);
  const [settings, setSettings] = useState<SettingsViewModel[]>([]);
  const [locations, setLocations] = useState<QueueChoiceVm[]>([]);
  const [changeMade, setChangeMade] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);

  const getSettings = () => {
    settingsClient
      .getAllSettings()
      .then((response) => {
        setSettings(response);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });

    getSiteLocations(state.user!.token)
      .then((response) => {
        var locs = response.filter(
          (x: QueueChoiceVm) => x.name != "Covid Questionnaire"
        );
        var sites = locs.filter(
          (x: QueueChoiceVm) => x.outcomeParentId == null
        );

        sites.forEach((x: QueueChoiceVm) => {
          x.children = locs.filter(
            (y: QueueChoiceVm) => y.outcomeParentId == x.id
          );
          if (x.children && x.children.length > 0) {
            x.children.forEach((y: QueueChoiceVm) => {
              y.children = locs.filter(
                (z: QueueChoiceVm) => z.outcomeParentId == y.id
              );
            });
          }
        });
        setLocations(sites);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateValue = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    id: string
  ) => {
    setChangeMade(true);
    setSettings(
      settings.map((setting: SettingsViewModel) => {
        if (setting.id === id) {
          setting.value = event.target.value;
          return setting;
        } else {
          return setting;
        }
      })
    );
  };

  const updateLocation = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    id: string
  ) => {
    setChangeMade(true);

    setLocations(
      locations.map((site: QueueChoiceVm) => {
        if (site.id === id) {
          site.expireInMinutes = Number(event.target.value);
          return site;
        } else {
          if (site.children && site.children.length > 0) {
            site.children.map((building: QueueChoiceVm) => {
              if (building.id === id) {
                building.expireInMinutes = Number(event.target.value);
                return building;
              } else {
                if (building.children && building.children.length > 0) {
                  building.children.map((room: QueueChoiceVm) => {
                    if (room.id === id) {
                      room.expireInMinutes = Number(event.target.value);
                      return room;
                    } else {
                      return room;
                    }
                  });
                } else {
                  return building;
                }
              }
            });
          } else {
            return site;
          }
          return site;
        }
      })
    );
  };

  const saveChanges = () => {
    var allSettings: AllSettingsViewModel = {
      settings: settings,
      locations: locations,
    };

    settingsClient
      .updateSettings(allSettings)
      .then((response) => {
      })
      .catch((error) => {
        console.log(error);
      });
    setChangeMade(false);
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <>
      <Paper className={classes.paper} elevation={2}>
        <Typography className={classes.header}>
          On Site Locator Settings
        </Typography>
        {changeMade ? (
          <ListItemIcon className={classes.saveIcon}>
            <SaveOutlinedIcon onClick={saveChanges} />
          </ListItemIcon>
        ) : (
          <ListItemIcon className={classes.saveIconDisabled}>
            <SaveOutlinedIcon />
          </ListItemIcon>
        )}
      </Paper>
      {!loading && settings.length > 0 && (
        <>
        <Paper elevation={2} className={classes.paper}>
          {settings.map((setting: SettingsViewModel) => (
            <>
              <div className={classes.textLines}>
                {setting.name}:
                <TextField
                  className={classes.settingsTextFields}
                  variant="outlined"
                  value={setting.value}
                  id={setting.id}
                  size="small"
                  onChange={(event) => {
                    updateValue(event, setting.id);
                  }}
                />
              </div>
              <Divider />
            </>
          ))}
</Paper>
<Paper elevation={2} className={classes.paper}>
  <div style={{fontWeight: 550}}>Locations Auto Expire Settings: (In Minutes, if 0 minutes then it uses the default expire timer as set above)</div>
  
          {locations.map((site: QueueChoiceVm) => (
            <>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {site.name}
                </AccordionSummary>
                <Divider />
                <AccordionDetails className={classes.accordion}>
                  <div>
                    {site.children &&
                      site.children.length > 0 &&
                      site.children.map((building: QueueChoiceVm) => (
                        <>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={
                                building.children &&
                                building.children.length > 0 ? (
                                  <ExpandMoreIcon />
                                ) : null
                              }
                            >
                              {building.children &&
                              building.children.length > 0 ? (
                                building.name
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div>{building.name}</div>
                                  <div style={{ float: "right" }}>
                                    <TextField
                                      className={classes.locationTextFields}
                                      variant="outlined"
                                      value={building.expireInMinutes}
                                      id={building.id}
                                      size="small"
                                      onChange={(event) => {
                                        updateLocation(event, building.id);
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </AccordionSummary>
                            <Divider />
                            <div>
                              {building.children &&
                                building.children.length > 0 &&
                                building.children.map((room: QueueChoiceVm) => (
                                  <>
                                    <AccordionDetails
                                      classes={{ root: classes.accordion }}
                                    >
                                      <div className={classes.textAccordion}>
                                        {room.name}
                                        <TextField
                                          className={classes.locationTextFields}
                                          variant="outlined"
                                          value={room.expireInMinutes}
                                          id={room.id}
                                          size="small"
                                          onChange={(event) => {
                                            updateLocation(event, room.id);
                                          }}
                                        />
                                      </div>
                                    </AccordionDetails>
                                    <Divider style={{ marginTop: "20px" }} />
                                  </>
                                ))}
                            </div>
                          </Accordion>
                          <Divider />
                        </>
                      ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            </>
          ))}
        </Paper>
        </>
      )}
    </>
  );
};

export default Settings;
