import { useApiClient } from "../../../../common/apiclients";
import {
  PersonViewModel,
  QuesitonnaireResponseListViewModel,
  QuestionnaireAndResponsesVm,
  QuestionnairesClient,
  QueueClient,
  QueueWorkItemViewModel,
} from "../../../../common/client";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SkeletonTimeLine } from "../../../../components/muiCore/TimeLine";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Question } from "../../../../components/core/QuestionSlider/QuestionItem";
import { AppBar, AppBarDivider } from "../../../../components/muiCore/Paper";
import {
  makeQuestionnaireControl,
  parseAnswers,
} from "../../../../components/core/Questionnaire";
import { AppBarIconButton } from "../../../../components/muiCore/Button";
import { PdfFileRegular } from "../../../../components/Icons/DoHIcons";
import { SaveQuestionnairePDF } from "../../../../components/core/PDFGenerator/SaveQuestionnairePDF";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { EditSolid } from "../../../../components/Icons/DoHIcons";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { OutcomeHeaderListItem } from "../../../../components/muiCore/ListItem";

export function QueueWorkItemResponses({
  questionnaire,
}: {
  questionnaire: QuestionnaireAndResponsesVm;
}) {
  const [showTop, setShowTop] = useState(2);
  const questions = questionnaire.questions;
  const answers = useMemo(() => {
    return parseAnswers(questionnaire.responses);
  }, [questionnaire]);
  const controls = useMemo(
    () => makeQuestionnaireControl(questionnaire.questions, answers),
    [questionnaire]
  );

  const hasMore = questions.length > showTop;
  const [sentryRef] = useInfiniteScroll({
    loading: false,
    hasNextPage: hasMore,
    onLoadMore: useCallback(() => setShowTop((t) => t + 5), []),
    disabled: false,
    rootMargin: "0px 0px 100px 0px",
  });

  const questionsRenders = useMemo(() => {
    return questions.slice(0, showTop).map((question, currentIndex) => (
      <Box key={question.id} marginBottom={1}>
        <Question
          question={question}
          control={controls.fields[question.id]}
          currentIndex={0}
          onBack={() => {}}
          onResult={() => {}}
          readonly={true}
        />
      </Box>
    ));
  }, [questions, showTop]);

  return (
    <div>
      <Box>
        <div>
          {questionsRenders}
          {hasMore && (
            <div ref={sentryRef}>
              <Divider />
            </div>
          )}
        </div>
      </Box>
    </div>
  );
}

export function QueueWorkItemQuestions({
  showing,
  ...props
}: {
  showing: boolean;
  queueId: string;
  queueWorkItem: QueueWorkItemViewModel;
  onEdit: (questionnaireId: string) => void;
}) {
  const queueClient = useApiClient(QueueClient);
  const qClient = useApiClient(QuestionnairesClient);
  const [loading, setLoading] = useState(true);
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [questionnaire, setQuestionnaire] =
    useState<QuestionnaireAndResponsesVm>();
  const [qrl, setQrl] = useState<QuesitonnaireResponseListViewModel[]>([]);
  const [selectedQuestionnaire, setSelectedQuestionnaire] =
    useState<QuesitonnaireResponseListViewModel>();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [savePdf, setSavePdf] = useState(0);

  const answers = useMemo(() => {
    return questionnaire ? parseAnswers(questionnaire.responses) : {};
  }, [questionnaire]);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (showing) {
      loadResponseList();
    }
  }, [showing]);

  useEffect(() => {
    if (selectedQuestionnaire) {
      loadResponses();
    } else {
      setLoadingQuestions(false);
      setQuestionnaire(undefined);
    }
  }, [selectedQuestionnaire]);

  async function loadResponses() {
    setLoadingQuestions(true);
    const q = await qClient.getQuestions(selectedQuestionnaire!.id);
    setQuestionnaire(q);
    setLoadingQuestions(false);
  }

  async function loadResponseList() {
    setLoading(true);
    const qrl = await queueClient.getAllQuestionnaireResponse(
      props.queueId,
      props.queueWorkItem.workItem.id!
    );

    if (qrl && qrl.length == 1) {
      setSelectedQuestionnaire(qrl[0]);
    }
    setQrl(qrl);
    setLoading(false);
  }

  return loading ? (
    <SkeletonTimeLine />
  ) : (
    <div>
      <AppBar elevation={2}>
        <div style={{ display: "flex", margin: "auto" }}>
          {qrl.length > 1 && (
            <>
              <AppBarDivider flexItem orientation="vertical" />
              <AppBarIconButton
                title={"Select Questionnaire"}
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                onClick={handleMenuClick}
              >
                <ListAltIcon />
              </AppBarIconButton>
            </>
          )}
          {/* <AppBarDivider flexItem orientation="vertical" />
          <AppBarIconButton
            title={"Edit Questionnaire"}
            aria-haspopup="true"
            variant="contained"
            color="primary"
            onClick={() => props.onEdit(selectedQuestionnaire!.id)}
            disabled={!selectedQuestionnaire}
          >
            <EditSolid />
          </AppBarIconButton> */}
          <AppBarDivider flexItem orientation="vertical" />
          <AppBarIconButton
            title={"Download Questionnaire PDF"}
            aria-label="Download"
            disabled={!selectedQuestionnaire}
            onClick={() => setSavePdf((s) => s + 1)}
          >
            <PdfFileRegular />
          </AppBarIconButton>
          {savePdf > 0 && questionnaire && (
            <SaveQuestionnairePDF
              key={savePdf}
              questions={questionnaire!.questions}
              answers={answers}
              person={props.queueWorkItem.workItem.person}
              completedAt={questionnaire!.completedAt}
              title={questionnaire!.name}
              filename={`${questionnaire!.name}.pdf`}
            />
          )}
          <AppBarDivider flexItem orientation="vertical" />
        </div>
      </AppBar>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {qrl.map((qr) => (
          <MenuItem
            value={qr.questionnaireName}
            onClick={() => {
              setSelectedQuestionnaire(qr);
              setAnchorEl(null);
            }}
          >
            {qr.questionnaireName}
          </MenuItem>
        ))}
      </Menu>
      {questionnaire && selectedQuestionnaire && (
        <>
          <Box marginBottom={1}>
            <OutcomeHeaderListItem
              //variant={MetricCategories[queueWorkItem!.status]}
              text={`${questionnaire.name}`}
              icon={<ListAltIcon/>}
            />
          </Box>
          <QueueWorkItemResponses questionnaire={questionnaire} />
        </>
      )}
      {loadingQuestions && (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}
