import React, { useContext, useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import { Finalisation } from "./Finalisation";
import { PersonKind, User } from "../state/State.model";
import {
  CheckInClient,
  FormDataClient,
  CheckInRequestViewModel,
  QueueClient,
  QuestionnaireIndex,
} from "../common/client";
import { useApiClient } from "../common/apiclients";
import { Context } from "../state/Store";
import { UpdateProfile } from "./UpdateProfile";
import { useSnackbar } from "notistack";
import { PublicQuestionnaire } from "./Questionnaire";
import { ErrorView } from "./ErrorView";
import PrimaryLocation from "./PrimaryLocation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    text: {
      display: "flex",
      justifyContent: "center",
    },
    textFields: {
      display: "flex",
      justifyContent: "center",
    },
  })
);

export const QRLanding = () => {
  const { state, dispatch } = useContext(Context);
  const [loading, setLoading] = useState<boolean>(true);
  const [location, setLocation] = useState<string | null>(null);
  const [user, setUser] = useState(state.user as User);
  const classes = useStyles();
  const locationData = window.location.href.slice(-36);
  const [checkout, setCheckout] = useState<boolean>(false);
  const locationClient = useApiClient(QueueClient);
  const checkInClient = useApiClient(CheckInClient);
  const [updateProfile, setUpdateProfile] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [checkInTime, setCheckInTime] = useState<Date>();
  const [checkOutTime, setCheckOutTime] = useState<Date>();
  const [errorView, setErrorView] = useState<boolean>(false);
  const [errorTile, setErrorTitle] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [questionnaire, setQuestionnaire] = useState<QuestionnaireIndex | null>(null);
  const [primaryLoation, setPrimaryLocation] = useState<boolean>(false);
  const [completed, setCompleted] = useState<string>();
  const userOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const [visitorOrContractor, setVisitorOrContractor] =
    useState<number>(0);
  const [primaryLocationExists, setPrimaryLocationExists] = useState<boolean>(
    state.user!.primaryLocation === locationData.toLowerCase() ? true : false
  );
  const [questionnairePassResult, setQuestionnairePassResult] =
    useState<boolean>(true);

  useEffect(() => {
    if (!location && user.firstName.length > 0) checkLocation(true);
  }, []);

  useEffect(() => {
    if (completed) {
      completed === "Pass"
        ? setQuestionnairePassResult(true)
        : setQuestionnairePassResult(false);
      setQuestionnaire(null);
      checkInUser();
    }
  }, [completed]);

  async function checkLocation(primaryLocation: boolean) {
    if (state.user?.primaryLocation === null && primaryLocation) {
      const usersDepartment = state.user?.departments.filter(
        (x: any) => x.id === state.user?.department
      );

      if (usersDepartment![0].name === "Visitor")
      setVisitorOrContractor(1)
      if (usersDepartment![0].name === "Contractor")
      setVisitorOrContractor(2)
      
      setLoading(false);
      setQuestionnaire(null)
      setPrimaryLocation(true);
    } else if (
      locationData
        .toLowerCase()
        .match(
          "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
        )
    ) {
      const usersDepartment = state.user?.departments.filter(
        (x: any) => x.id === state.user?.department
      );

      if (
        usersDepartment &&
        (usersDepartment[0]!.name === "Visitor" ||
          usersDepartment[0]!.name === "Contractor")
      ) {
        checkInClient
          .getStatus()
          .then((response) => {
            if (response.error === 1) {
              checkInUser();
            } else if (
              new Date(response.checkInTime!).getTime() <
              new Date().getTime() - Number(response.covidTimer) * 24 * 60 * 1000 + userOffset
            ) {
              setQuestionnaire(QuestionnaireIndex.Covid_Survey);
              //checkInUser();
              setLoading(false);
            } else if (
              new Date(response.checkInTime!).getTime() <
              new Date().getTime() - Number(response.declarationTimer) * 60 * 1000 + userOffset
            ) {
              setQuestionnaire(QuestionnaireIndex.Declaration);
              //checkInUser();
              setLoading(false);
            } else {
              checkInUser();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        checkInUser();
      }
    } else {
      setErrorTitle("Location Error");
      setErrorMessage("Sorry, no valid location was given");
      setErrorView(true);
      setLoading(false);
    }
  }

  const checkInUser = async () => {
    const location = await locationClient
      .getQueueOutcome(locationData)
      .then((response: any) => {
        if (response[0].error === 0) {
          let location = "";
          if (response[2].name !== null) {
            location = `${response[2].name} > ${response[1].name} > ${response[0].name}`;
          } else {
            location = `${response[1].name} > ${response[0].name}`;
          }
          setLocation(location);
          if (location) {
            const checkIn: CheckInRequestViewModel = {
              personId: user!.id,
              queueOutcomeChoiceId: locationData,
            };
            checkInClient.checkIn(checkIn).then((res: any) => {
              setCheckInTime(new Date(Date.parse(res.checkInTime)));
              setLoading(false);
            });
          }
        } else if (response[0].error === 2) {
          setErrorTitle("Location Error");
          setErrorMessage("Sorry, no valid location was given");
          setErrorView(true);
          setLoading(false);
        }
      })
      .catch((error: any) => console.log(error));
  };

  const checkoutUser = () => {
    checkInClient.checkout(locationData).then((res: any) => {
      setCheckOutTime(new Date(Date.parse(res.checkOutTime)));
      setCheckout(true);
    });
  };

  const saveUserProfile = (newValues: any) => {
    checkInClient.update(newValues).then((res) => {
      if (res.firstName !== "") {
        dispatch({
          type: "SET_USERINFO",
          payload: {
            ...state.user,
            firstName: res.firstName,
            lastName: res.lastName,
            contactNumber: res.contactNumber,
            department: res.department,
            primaryLocation: res.primaryLocation,
          },
        });
        setPrimaryLocationExists(
          res.primaryLocation === locationData.toLowerCase() ? true : false
        );
        setUpdateProfile(false);
        enqueueSnackbar("Details Updated", { variant: "success" });
      } else {
        setUpdateProfile(false);
        enqueueSnackbar("Error updating details", { variant: "error" });
      }
    });
  };

  const cancelUpdateProfile = () => {
    setUpdateProfile(false);
  };

  const hadelPrimaryLocation = (locationId: string) => {
    dispatch({
      type: "SET_USERINFO",
      payload: {
        ...state.user,
        primaryLocation: locationId,
      },
    });
    setPrimaryLocation(false);
    checkLocation(false);
  };

  return (
    <>
      {loading && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {!loading &&
        updateProfile &&
        !errorView &&
        !questionnaire &&
        !primaryLoation && (
          <UpdateProfile
            newValues={saveUserProfile}
            onCancel={cancelUpdateProfile}
          />
        )}

      {!loading &&
        !updateProfile &&
        !errorView &&
        !questionnaire &&
        !primaryLoation &&
        checkInTime && (
          <Finalisation
            user={{ ...state.user, locationHistory: [""] }}
            location={location}
            onCheckout={checkoutUser}
            checkout={checkout}
            checkInTime={checkInTime}
            checkOutTime={checkOutTime}
            updateProfile={setUpdateProfile}
            questionnairePassResult={questionnairePassResult}
            primaryLocation={primaryLocationExists}
          />
        )}
      {!loading &&
        !updateProfile &&
        !errorView &&
        questionnaire &&
        !primaryLoation && (
          <PublicQuestionnaire
            token={state.user?.token}
            questionnaireIndex={questionnaire}
            completed={(completed: string) => setCompleted(completed)}
          />
        )}

      {!loading && !updateProfile && !questionnaire && primaryLoation && !errorView && (
        <PrimaryLocation
          location={locationData}
          onSavePrimaryLocation={hadelPrimaryLocation}
          userDetails={state.user}
          visitorOrContractor={visitorOrContractor}
        />
      )}

      {errorView && <ErrorView title={errorTile} message={errorMessage} />}
    </>
  );
};
