import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { MainListItems } from "../../../data/listItems";
import { PersonAvatar } from "../../../components/muiCore/Avatar";
import { Context } from "../../../state/Store";
import * as api from "../../../data/api";
import { getQueues } from "../../../data/api";
import { useHistory, useLocation } from "react-router";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { PersonKind, UserPermissions } from "../../../state/State.model";
import { TokenContext } from "../../../common/apiclients";
import nyrstar1 from "../../../assets/images/nyrstar1.png"
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { AdminRoutes, useViewQueueMatch, viewQueueUrl } from "../Routes";
import { RolesSoild } from "../../../components/core/Manager/Icons/Icons";
import { hasPermission } from "../../utils/permisson";
import { Backdrop, CircularProgress } from "@material-ui/core";
import ViewListIcon from "@material-ui/icons/ViewList"
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PersonPinCircleOutlinedIcon from '@material-ui/icons/PersonPinCircleOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import { Queue } from "../../../common/client";

const drawerWidth = 285;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 48,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(11),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(11),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  image: {
    height: "80px",
    width: "190px"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  //GLOBAL STATE
  const { state, dispatch } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [queues, setQueues] = useState<any[]>();

  useEffect(() => {}, []);

  let location = useLocation();
  let history = useHistory();
  const queueMatch = useViewQueueMatch();
  const currentQueue =
    queues && queueMatch
      ? queues.find((q) => q.id === queueMatch.params.queueId)
      : undefined;

  const [open, setOpen] = React.useState(true);
  const [loggedInUser, setLoggedInUser] = useState<string>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
   
   // if (!state.user?.token) {                         //// had to comment out to be able to access queue editor or it redirects back to admin
      let token: any =  null;
      
   
      if (location.hash != "") {
        token = location.hash.split("=")[1];
        history.push("/admin");
      } else {
        if (state.user?.token) {
          token =  state.user?.token //localStorage.getItem("user:authToken")/;
        }
      }

      if (token ) {
       
        api.getUserInfo(token).then((userInfo: any) => {
          dispatch({
            type: "SET_USERINFO",
            payload: {
              ...state.user,
              token: token,
              rememberMe: true,
              firstName: userInfo.firstName,
              lastName: userInfo.lastName,
              contactNumber: userInfo.contactNumber,
              kind: userInfo.kind,
              roles: userInfo.roles,
            },
          });
          setLoading(false);
        });
      } else {
        window.location.href = "/api/user/login";
      }

  }, []);

  const RedirectCalculater = () => {
    const user = state.user;
    if (queues && queues?.length > 0) {
      return "/admin/view/" + queues![0].id;
    }
    if (user?.roles.find((x) => x.permissions == UserPermissions.ModifyRoles)) {
      return "/admin/roles";
    }
    if (
      user?.roles.find(
        (x) => x.permissions == UserPermissions.ModifyQueueMember
      )
    ) {
      return "/admin/queuesmanagement";
    }
    return "";
  };

  useEffect(() => {
    if (state.user?.firstName.length !== 0) {
    setLoggedInUser(`${state.user?.firstName} ${state.user?.lastName}`);
    if (state.user?.roles.find((x) => x.permissions == UserPermissions.SuperUser) || state.user?.roles.find((x) => x.permissions == UserPermissions.ContactTracer)) {         
      getQueues(state.user?.token).then((res) => {
        setQueues(res.sort((a: Queue, b: Queue) => a.queueIndex.toString().localeCompare( b.queueIndex.toString())));
      });
    } else { 
      history.push('/accessdenied')
    }
  }
  }, [state.user?.roles]);

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Admin Dashboard
          </Typography>
          {state.user && (
            <>
              <PersonAvatar
                fullName={loggedInUser} //onClick={(event: React.MouseEvent<HTMLButtonElement>)=> handleClick(event)}
              />
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Logout</MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
        <img
                                className={classes.image}
                                src={nyrstar1}
                                alt="Site Logo"/>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <MainListItems
            queueItems={queues}
            onItemClick={(item) => history.push(viewQueueUrl(item.id))}
            selectedItem={currentQueue}
          />
        </List>
        <Divider />
     
         <ListItem
            button
            onClick={() => {
              history.push("/admin/uploads");
            }}
          >
            <ListItemIcon>
            <CloudUploadIcon />
            </ListItemIcon>
            <ListItemText primary="Upload Check Ins" />
          </ListItem>
     
          <Divider />
         {hasPermission(state.user, UserPermissions.SuperUser) && (
           <ListItem
           button
           onClick={() => {
             history.push("/admin/primarylocation");
           }}
         >
           <ListItemIcon>
             <PersonPinCircleOutlinedIcon />
           </ListItemIcon>
           <ListItemText primary="Primary Locations" />
         </ListItem>
        )}
         {hasPermission(state.user, UserPermissions.ModifyTeams) && (
           <ListItem
           button
           onClick={() => {
             history.push("/admin/departments");
           }}
         >
           <ListItemIcon>
             <RolesSoild />
           </ListItemIcon>
           <ListItemText primary="Departments" />
         </ListItem>
        )}
        {hasPermission(state.user, UserPermissions.ModifyRoles) && (
          <ListItem button onClick={() => history.push("/admin/roles")}>
            <ListItemIcon>
              <RolesSoild />
            </ListItemIcon>
            <ListItemText primary="Roles" />
          </ListItem>
        )}
        {hasPermission(state.user, UserPermissions.SuperUser) && (
           <ListItem
           button
           onClick={() => {
             history.push("/admin/settings");
           }}
         >
           <ListItemIcon>
             <SettingsIcon />
           </ListItemIcon>
           <ListItemText primary="Settings" />
         </ListItem>
        )}
        {/* {hasPermission(state.user, UserPermissions.ModifyQueueMember) && (
          <ListItem
            button
            onClick={() => {
              history.push("/admin/queuesmanagement");
            }}
          >
            <ListItemIcon>
              <RolesSoild />
            </ListItemIcon>
            <ListItemText primary="Queues" />
          </ListItem>
        )} */}

        {/* <ListItem*/}
        {/*	selected={false}*/}
        {/*	button*/}
        {/*	onClick={() => {*/}
        {/*		history.push("/admin/summary");*/}
        {/*	}}*/}
        {/*> */}
        {/*	<ListItemIcon>*/}
        {/*		<DashboardIcon />*/}
        {/*	</ListItemIcon>*/}
        {/*	<ListItemText primary="Summary" />*/}
        {/*</ListItem> */}
        {/* <ListItem selected={false} button>
					<ListItemIcon>
						<DashboardIcon />
					</ListItemIcon>
					<ListItemText primary="Team" />
				</ListItem> */}
        {/*<List>{secondaryListItems}</List> */}
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {!loading && state.user?.token && queues && queues.length > 0 ? (
            <TokenContext.Provider value={state.user.token}>
              {AdminRoutes(queues, RedirectCalculater())}
            </TokenContext.Provider>
          ) : (
            <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
          )}
        </Container>
      </main>
    </div>
  );
};

export default Dashboard;
