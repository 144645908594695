import React, { useContext } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { QuestionnaireList } from "./views/Questionnaire/QuestionnaireList";
import { QueueList } from "./views/QueueManagement/QueueList";
import { QueueEditor } from "./views/QueueManagement/QueueEditor";
import { Queue } from "./views/Queue";
import  TeamManager  from "./views/Departments/TeamManager";
import SummaryView from "../../modules/admin/views/SummaryView";
import { QuestionnaireEditor } from "./views/Questionnaire/QuestionnaireEditor";
import { Temp } from "./views/TEMP";
import { QueueAccess } from "./views/QueueManagement/QueueAccess";
import RoleManager from "./views/RoleManager/RoleManager";
import QueueManager from "./views/QueueManager/QueueManager";
import { Context } from "../../state/Store";
import { Permissions } from "../../common/client";
import { UserPermissions } from "../../state/State.model";
import { hasPermission } from "../utils/permisson";
import { UploadCSV } from "./views/UploadCSV/UploadCSV";
import PrimaryLocationManager from "./views/PrimaryLocationManager/PrimaryLocationManager";
import Settings from "./views/Settings/Settings";

export function questionnaireHref(id: string) {
  return `/admin/questionnaire/${id}`;
}

export function editQueueLink(id: string) {
  return `/admin/queue/${id}`;
}

export function AdminRoutes(queues: any[], redirectTo: string) {
  const { state } = useContext(Context);
  return (
    <Switch>
      <Route exact path="/admin/testing">
        <Temp />
      </Route>
      <Route exact path={questionnaireListUrl()}>
        <QuestionnaireList />
      </Route>
      <Route path="/admin/questionnaire/:id">
        <QuestionnaireEditor />
      </Route>
      <Route exact path="/admin/queues">
        <QueueList />
      </Route>
      <Route path="/admin/queue/:id">
        <QueueEditor />
      </Route>
      <Route path="/admin/queue/">
        <QueueEditor />
      </Route>
      <Route path="/admin/access/">
        <QueueAccess />
      </Route>
      <Route path="/admin/departments">
         <TeamManager />
       </Route>
       <Route path="/admin/uploads">
         <UploadCSV />
       </Route>
      {state.user?.roles.find(
        (x) => x.permissions == UserPermissions.SuperUser
      ) && (

        <Route path="/admin/summary">
          <SummaryView />
        </Route>
      )}
       {state.user?.roles.find(
        (x) => x.permissions == UserPermissions.SuperUser
      ) && (

        <Route path="/admin/primarylocation">
          <PrimaryLocationManager />
        </Route>
      )}

      {hasPermission(state.user, UserPermissions.ModifyRoles) && (
        <Route path="/admin/roles">
          <RoleManager />
        </Route>
      )}
      {hasPermission(state.user, UserPermissions.ModifyQueueMember) && (
        <Route path="/admin/queuesmanagement">
          <QueueManager />
        </Route>
      )}

      {state.user?.roles.find(
        (x) => x.permissions == UserPermissions.SuperUser
      ) && (

        <Route path="/admin/settings">
          <Settings />
        </Route>
      )}

      <Route>
        {queues && (
          <Switch>
            <Route path="/admin/view/:queueId">
              <Queue queues={queues} />
            </Route>
            <Route>
              <Redirect to={redirectTo} />
            </Route>
          </Switch>
        )}
      </Route>
    </Switch>
  );
}
export function viewQueueUrl(queueId: string) {
  return "/admin/view/" + queueId;
}

export function useViewQueueMatch() {
  return useRouteMatch<{ queueId: string }>("/admin/view/:queueId");
}

export function questionnaireListUrl() {
  return "/admin/questionnaires";
}

export function questionnaireEditUrl(id: string) {
  return "/admin/questionnaire/" + id;
}
