import React, { useEffect, useState } from "react";
import { User } from "../../../state/State.model";
import { ValidationTextField } from "../../muiCore/TextField";
import { ValidatedForm } from "./ValidatedForm";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { searchAddresses } from "../../../data/api";

import { LoginUserViewModel, PersonKind } from "../../../common/client";

export interface IUserRegistrationProps {
  onSubmit: (isFormValid: boolean, user: LoginUserViewModel) => void;
  allDetails: boolean;
}

export const UserRegistration = (props: IUserRegistrationProps) => {
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({
    mobile: {
      valid: true,
      errorMessage: "",
    },
    firstName: {
      valid: true,
      errorMessage: "",
    },
    lastName: {
      valid: true,
      errorMessage: "",
    },
    dateOfBirth: {
      valid: true,
      errorMessage: "",
    },
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [address, setAddress] = useState("");
  const [longitude, setLongitude] = useState<number>();
  const [latitude, setLatitude] = useState<number>();
  const [gpPractice, setGPPractice] = useState("");
  const [gpName, setGPName] = useState("");
  const [sex, setSex] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [addressOptions, setAddressOptions] = React.useState([]);

  const isFormValid = (): boolean => {
    let valid = true;
    let errors = { ...formErrors };

    if (!firstName) {
      errors.firstName.valid = false;
      errors.firstName.errorMessage = "First name can not be blank";
      valid = false;
    } else {
      errors.firstName.valid = true;
      errors.firstName.errorMessage = "";
    }

    if (!lastName) {
      errors.lastName.valid = false;
      errors.lastName.errorMessage = "Last name can not be blank";
      valid = false;
    } else {
      errors.lastName.valid = true;
      errors.lastName.errorMessage = "";
    }

    if (!contactNumber) {
      errors.mobile.valid = false;
      errors.mobile.errorMessage = "Contact number can not be blank";
      valid = false;
    } else {
      errors.mobile.valid = true;
      errors.mobile.errorMessage = "";

      if (!/^[0][0-9]{9}$|^[1300][0-9]{9}$/.test(contactNumber)) {
        errors.mobile.valid = false;
        errors.mobile.errorMessage =
          "Please provide valid contact number 10 digits with no spaces - eg. 0400123456 or 0362291643";
        valid = false;
      }
    }

    if (!dateOfBirth) {
      errors.dateOfBirth.valid = false;
      errors.dateOfBirth.errorMessage = "Date Of Birth can not be blank";
      valid = false;
    } else if (!dobValidator(dateOfBirth)) {
      errors.dateOfBirth.valid = false;
      errors.dateOfBirth.errorMessage = "Date Of Birth invalid";
      valid = false;
    } else {
      errors.dateOfBirth.valid = true;
      errors.dateOfBirth.errorMessage = "";
    }



    setFormErrors(errors);
    return valid;
  };

  const dobValidator = (dob: string): boolean => {
    let dobObj = new Date(dob);
    let today = new Date();
    let startDate=new Date('1900-01-01');

    return (dobObj <= today && dobObj >=startDate);
  };
  const addressValidator = (address: string): boolean => {
    return address.charAt(0) !== " ";
  };

  useEffect(() => {
    if (address && address.length > 3) {
      searchAddresses(address)
        .then((d) => setAddressOptions(d))
        .catch((e) => setAddressOptions([]));
    }
  }, [address]);

  return (
    <ValidatedForm>
      <ValidationTextField
        error={!formErrors.firstName.valid}
        helperText={formErrors.firstName.errorMessage}
        margin="normal"
        fullWidth
        id="firstName"
        label="First Name"
        name="firstName"
        autoFocus
        required
        onChange={(e: any) => {
          setFirstName(e.target.value);
        }}
        variant="outlined"
        disabled={loading}
      />
      <ValidationTextField
        error={!formErrors.lastName.valid}
        helperText={formErrors.lastName.errorMessage}
        margin="normal"
        fullWidth
        id="lastName"
        label="Last Name"
        name="lastName"
        required
        onChange={(e: any) => {
          setLastName(e!.target.value);
        }}
        variant="outlined"
        validation={{ required: true }}
        disabled={loading}
      />
      <ValidationTextField
        variant="outlined"
        error={!formErrors.mobile.valid}
        helperText={
          !formErrors.mobile.valid ? formErrors.mobile.errorMessage : ""
        }
        margin="normal"
        fullWidth
        id="mobile"
        label="Phone number (mobile preferred)"
        name="mobileNumber"
        required
        onChange={(e: any) => {
          setContactNumber(e.target.value);
        }}
        //value={txtMobile || ''}
        type={"tel"}
        disabled={loading}
      />
      <ValidationTextField
        variant="outlined"
        error={!formErrors.dateOfBirth.valid}
        helperText={
          !formErrors.dateOfBirth.valid
            ? formErrors.dateOfBirth.errorMessage
            : ""
        }
        margin="normal"
        fullWidth
        id="dateOfBirth"
        label="Date of Birth"
        name="dateOfBirth"
        required
        onChange={(e: any) => {
          setDateOfBirth(e.target.value);
        }}
        inputProps={{max: "9999-12-12"}}
        InputLabelProps={{
          shrink: true,
        }}
        type={"date"}
        disabled={loading}
      />
      {/* <AddressComplete state={addressForm} label="Residential Address" />
      <Box my={2}>
        <RenderAddressFields state={addressForm} /> */}
      {/* </Box> */}
      {/*{props.allDetails && (*/}
      {/*  <>*/}
      {/*    <FormControl*/}
      {/*      variant="outlined"*/}
      {/*      fullWidth={true}*/}
      {/*      style={{ marginTop: "16px" }}*/}
      {/*    >*/}
      {/*      <InputLabel id="sexOutlinedLabel">Sex</InputLabel>*/}
      {/*      <Select*/}
      {/*        labelId="sexOutlinedLabel"*/}
      {/*        id="sex"*/}
      {/*        value={sex}*/}
      {/*        onChange={(e: any) => {*/}
      {/*          setSex(e.target.value);*/}
      {/*        }}*/}
      {/*        label="Sex"*/}
      {/*        fullWidth={true}*/}
      {/*      >*/}
      {/*        <MenuItem value={"male"}>Male</MenuItem>*/}
      {/*        <MenuItem value={"female"}>Female</MenuItem>*/}
      {/*        <MenuItem value={"other"}>Other</MenuItem>*/}
      {/*      </Select>*/}
      {/*    </FormControl>*/}
      
      {/*    <ValidationTextField*/}
      {/*      variant="outlined"*/}
      {/*      margin="normal"*/}
      {/*      fullWidth*/}
      {/*      id="emailAddress"*/}
      {/*      label="Email Address"*/}
      {/*      name="emailAddress"*/}
      {/*      onChange={(e: any) => {*/}
      {/*        setEmailAddress(e.target.value);*/}
      {/*      }}*/}
      {/*      disabled={loading}*/}
      {/*    />*/}
      {/*  </>*/}
      {/*)}*/}
      
      {!props.allDetails && (
      <>
      <ValidationTextField
        variant="outlined"
        margin="normal"
        fullWidth
        id="gpName"
        label="GP Name"
        name="gpName"
        onChange={(e: any) => {
          setGPName(e.target.value);
        }}
        disabled={loading}
      />
      <ValidationTextField
        variant="outlined"
        margin="normal"
        fullWidth
        id="gpPractice"
        label="Practice"
        name="gpPractice"
        onChange={(e: any) => {
          setGPPractice(e.target.value);
        }}
        disabled={loading}
      />
          </>
        )}
      <br />
      <Button
        disabled={loading}
        type="submit"
        fullWidth
        variant="contained"
        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          event.preventDefault();
          setLoading(true);
          if (isFormValid()) {
            //const addressFull = addressForm.toObject();
            // props.onSubmit(true, {
            //   firstName: firstName,
            //   lastName: lastName,
            //   contactNumber: contactNumber,
            //   department: "",
            //   kind: PersonKind.Visitor,
            // });
          } else {
            setLoading(false);
          }
        }}
      >
        {loading && <CircularProgress style={{ color: "white" }} />}
        {!loading && "Next"}
      </Button>
    </ValidatedForm>
  );
};
