import React, { useContext, useEffect, useMemo, useState } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { ValidationTextField } from "../components/muiCore/TextField";
import Button from "@material-ui/core/Button";
import {
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Slide,
} from "@material-ui/core";
import { Context } from "../state/Store";
import { ValidatedForm } from "../components/core/forms/ValidatedForm";
import { useAuth } from "../auth/useAuth";
import nyrstar1 from "../assets/images/nyrstar1.jpg";
import { userInfo } from "os";
import {
  DepartmentIdentifier,
  OutcomeIdentifier,
  QueueChoiceVm,
  TeamViewModel,
} from "../common/client";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  heading: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.3rem!important",
      // added the center
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.3rem!important",
      fontWeight: "600!important",
      lineHeight: " 1.4375rem",
      letterSpacing: "-.01875rem",
    },
  },
  image: {},
  selectField: {
    marginTop: "15px",
    marginBottom: "5px",
    "& .MuiFormHelperText-root": {
      fontSize: "0.9rem!Important",
    },

    "& .MuiInputLabel-shrink": {
      transform: "Translate(0,-5.5px) scale (0.75)!Important",
    },

    "& .MuiInputLabel-formControl": {
      top: "0px",
      left: "11px!Important",
      position: "absolute",
      transform: "translate(0, 24px) scale(1)",
    },

    fontSize: "1rem",
    lineHeight: " 1rem",
    letterSpacing: "-.01875rem",

    [theme.breakpoints.up("sm")]: {
      fontSize: "1.25rem",
      lineHeight: " 1.4375rem",
      letterSpacing: "-.01875rem",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiFormLabel-root": {
        fontSize: "1rem!Important",
      },
      "& .MuiOutlinedInput-root ": {
        fontSize: "1rem!Important",
      },
    },
  },
  selectFieldSite: {
    marginTop: "15px",
    marginBottom: "5px",
    "& .MuiFormHelperText-root": {
      fontSize: "0.9rem!Important",
    },

    "& .MuiInputLabel-shrink": {
      transform: "Translate(0,-5.5px) scale (0.75)!important",
    },

    "& .MuiInputLabel-formControl": {
      top: "0px",
      left: "11px!important",
      position: "absolute",
      transform: "translate(0, 24px) scale(1)",
    },

    fontSize: "1rem",
    lineHeight: " 1rem",
    letterSpacing: "-.01875rem",

    [theme.breakpoints.up("sm")]: {
      fontSize: "1.25rem",
      lineHeight: " 1.4375rem",
      letterSpacing: "-.01875rem",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiFormLabel-root": {
        fontSize: "1rem!Important",
      },
      "& .MuiOutlinedInput-root ": {
        fontSize: "1rem!Important",
      },
    },
  },
  selectFieldOne: {
    marginTop: "0px",
    marginBottom: "5px",
    "& .MuiFormHelperText-root": {
      fontSize: "0.9rem!Important",
    },

    "& .MuiInputLabel-shrink": {
      transform: "Translate(0,-5.5px) scale (0.75)!important",
    },

    "& .MuiInputLabel-formControl": {
      top: "0px",
      left: "11px!important",
      position: "absolute",
      transform: "translate(0, 24px) scale(1)",
    },

    fontSize: "1rem",
    lineHeight: " 1rem",
    letterSpacing: "-.01875rem",

    [theme.breakpoints.up("sm")]: {
      fontSize: "1.25rem",
      lineHeight: " 1.4375rem",
      letterSpacing: "-.01875rem",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiFormLabel-root": {
        fontSize: "1rem!Important",
      },
      "& .MuiOutlinedInput-root ": {
        fontSize: "1rem!Important",
      },
    },
  },
  selectFieldtwo: {
    marginTop: "0px",
    marginBottom: "5px",
    "& .MuiFormHelperText-root": {
      fontSize: "0.9rem!Important",
    },

    "& .MuiInputLabel-shrink": {
      transform: "Translate(0,-5.5px) scale (0.75)!important",
    },

    "& .MuiInputLabel-formControl": {
      top: "0px",
      left: "11px!important",
      position: "absolute",
      transform: "translate(0, 24px) scale(1)",
    },

    fontSize: "1rem",
    lineHeight: " 1rem",
    letterSpacing: "-.01875rem",

    [theme.breakpoints.up("sm")]: {
      fontSize: "1.25rem",
      lineHeight: " 1.4375rem",
      letterSpacing: "-.01875rem",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiFormLabel-root": {
        fontSize: "1rem!Important",
      },
      "& .MuiOutlinedInput-root ": {
        fontSize: "1rem!Important",
      },
    },
  },
  error: {
    color: "red",
    paddingLeft: "14px",
    fontSize: "0.9rem!important",
    letterSpacing: "0.0075rem",
  },
}));

export const UpdateProfile = (props: any) => {
  const classes = useStyles();
  const { state, dispatch } = useContext(Context);
  const [departmentList, setDepartmentList] = useState<any>([]);
  const [firstName, setFirstName] = useState(state.user!.firstName);
  const [lastName, setLastName] = useState(state.user!.lastName);
  const [contactNumber, setContactNumber] = useState(state.user!.contactNumber);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [department, setDepartment] = useState(state.user!.department);
  const BLANKID = "00000000-0000-0000-0000-000000000000";
  const [loading, setLoading] = useState<boolean>(true);
  const [allLocations, setAllLocations] = useState<any>([]);
  const [site, setSite] = useState<string>("");
  const [allSites, setAllSites] = useState<any>([]);
  const [building, setBuilding] = useState<string>("");
  const [allBuildings, setAllBuildings] = useState<any[]>([
    { id: BLANKID, name: "No room for selected site" },
  ]);
  const [room, setRoom] = useState<string>("");
  const [allRooms, setAllRooms] = useState<any[]>([
    { id: BLANKID, name: "No room for selected bulding" },
  ]);

  const cachedImg = useMemo(() => nyrstar1, []);
  const [formErrors, setFormErrors] = useState({
    mobile: {
      valid: true,
      errorMessage: "",
    },
    firstName: {
      valid: true,
      errorMessage: "",
    },
    lastName: {
      valid: true,
      errorMessage: "",
    },
    department: {
      valid: true,
      errorMessage: "",
    },
    building: {
      valid: true,
      errorMessage: "",
    },
    room: {
      valid: true,
      errorMessage: "",
    },
    site: {
      valid: true,
      errorMessage: "",
    },
  });

  const handleDepartmentChange = (event: any) => {
    setDepartment(event.target.value as string);
  };

  const handleSiteChange = (event: any) => {
    setSite(event.target.value as string);
    var buildings = allLocations.filter(
      (item: QueueChoiceVm) =>
        item.outcomeParentId === (event.target.value as string)
    );
    buildings.length > 0
      ? setAllBuildings(buildings)
      : setAllBuildings([{ id: BLANKID, name: "No Buildings for this site" }]);
  };

  const handleBuildingChange = (event: any) => {
    setBuilding(event.target.value as string);
    var rooms = allLocations.filter(
      (item: QueueChoiceVm) =>
        item.outcomeParentId === (event.target.value as string)
    );
    setRoom(BLANKID);
    rooms.length > 0
      ? setAllRooms(rooms)
      : setAllRooms([{ id: BLANKID, name: "No Rooms for this Building" }]);
  };

  const handleRoomChange = (event: any) => {
    setRoom(event.target.value as string);
  };

  const isFormValid = (): boolean => {
    let valid = true;
    let errors = { ...formErrors };

    if (!firstName) {
      errors.firstName.valid = false;
      errors.firstName.errorMessage = "First name can not be blank";
      valid = false;
    } else {
      errors.firstName.valid = true;
      errors.firstName.errorMessage = "";
    }

    if (!lastName) {
      errors.lastName.valid = false;
      errors.lastName.errorMessage = "Last name can not be blank";
      valid = false;
    } else {
      errors.lastName.valid = true;
      errors.lastName.errorMessage = "";
    }

    if (!contactNumber) {
      errors.mobile.valid = false;
      errors.mobile.errorMessage = "Contact number can not be blank";
      valid = false;
    } else {
      errors.mobile.valid = true;
      errors.mobile.errorMessage = "";

      if (!/^[0][0-9]{9}$|^[1300][0-9]{9}$/.test(contactNumber)) {
        errors.mobile.valid = false;
        errors.mobile.errorMessage =
          "Please provide valid contact number 10 digits with no spaces - eg. 0400123456 or 0362291643";
        valid = false;
      }
    }

    if (!department) {
      errors.department.valid = false;
      errors.department.errorMessage = "Department name can not be blank";
      valid = false;
    } else {
      errors.department.valid = true;
      errors.department.errorMessage = "";
    }
    if (building.length === 0 || building === BLANKID) {
      errors.building.valid = false;
      errors.building.errorMessage = "Building is required";
      valid = false;
    } else {
      errors.building.valid = true;
      errors.building.errorMessage = "";
    }

    var allRooms: QueueChoiceVm[] = [];
    if (building !== BLANKID) {
      allRooms = allLocations.filter(
        (x: QueueChoiceVm) => x.outcomeParentId === building
      );
    }

    if (
      building !== BLANKID &&
      allRooms.length > 0 &&
      room !== allRooms.find((x: QueueChoiceVm) => room === x!.id)?.id
    ) {
      errors.room.valid = false;
      errors.room.errorMessage = "Room is required";
      valid = false;
    } else {
      errors.room.valid = true;
      errors.room.errorMessage = "";
    }

    if (site === BLANKID) {
      errors.site.valid = false;
      errors.site.errorMessage = "Site is required";
      valid = false;
    } else {
      errors.site.valid = true;
      errors.site.errorMessage = "";
    }

    if (
      !(
        department ===
          departmentList.find((x: TeamViewModel) => x.name === "Visitor").id ||
        department ===
          departmentList.find((x: TeamViewModel) => x.name === "Contractor").id
      ) &&
      allLocations.find((x: QueueChoiceVm) => x.name === "I do not know...") &&
      site ==
        allLocations.find((x: QueueChoiceVm) => x.name === "I do not know...")
          .id
    ) {
      errors.site.valid = false;
      errors.site.errorMessage =
        "Non Visitors/Contractors must have a valid site";
      valid = false;
    }

    setFormErrors(errors);
    return valid;
  };

  const onSubmit = () => {
    if (isFormValid()) {
      var primaryLocation = "";
      if (room !== BLANKID) {
        primaryLocation = room;
      } else if (building !== BLANKID) {
        primaryLocation = building;
      } else {
        primaryLocation = site;
      }
      const newValues = {
        firstName: firstName,
        LastName: lastName,
        contactNumber: contactNumber,
        department: department,
        primaryLocation: primaryLocation,
      };
      props.newValues(newValues);
    } else {
      setLoadingSave(false);
      setLoadingCancel(false);
    }
  };

  const run = () => {
    if (
      state.user?.departments &&
      state.user?.departments.length > 0 &&
      departmentList.length === 0
    ) {

      let list = state.user?.departments.filter(
        (x: TeamViewModel) =>
          x.departmentIdentifier !== DepartmentIdentifier.Visitor
      );
      list = list.filter(
        (x: TeamViewModel) =>
          x.departmentIdentifier !== DepartmentIdentifier.Contractor
      );
      let visitor = state.user?.departments.filter(
        (x: TeamViewModel) =>
          x.departmentIdentifier === DepartmentIdentifier.Visitor
      );
      let contractor = state.user?.departments.filter(
        (x: TeamViewModel) =>
          x.departmentIdentifier === DepartmentIdentifier.Contractor
      );
      list.sort((a: TeamViewModel, b: TeamViewModel) =>
        a.name!.localeCompare(b.name!)
      );
      let temp = [visitor[0], contractor[0], ...list];
      setDepartmentList(temp);

      if (state.user?.locations && state.user?.locations.length > 0) {
        var locations = state.user?.locations;
        if (state.user?.department === visitor[0].id) {
          locations = filterOther(locations);
        } else if (state.user?.department === contractor[0].id) {
          locations = filterVisiting(locations);
        } else {
          locations = filterVisiting(locations);
          locations = filterDontKnow(locations);
        }
        locations = filterQuestionnaires(locations);
        setAllLocations(locations);
        var sites = locations.filter(
          (item: QueueChoiceVm) => item.outcomeParentId === null
        );
        setAllSites(sites);
        var primaryLocation = locations.find(
          (x: QueueChoiceVm) => x.id === state.user?.primaryLocation
        );
        var buildings = locations.filter((x: QueueChoiceVm) =>
          sites.map((x) => x.id).includes(x.outcomeParentId!)
        );
        setAllBuildings(buildings);

        if (buildings.length > 0) {
          setAllRooms(
            locations.filter((x: QueueChoiceVm) =>
              buildings.map((x) => x.id).includes(x.outcomeParentId!)
            )
          );
        }

        if (primaryLocation === undefined) {
          setSite(BLANKID);
          setBuilding(BLANKID);
          setRoom(BLANKID);
        } else if (primaryLocation?.outcomeParentId === null) {
          setSite(primaryLocation!.id);
          var buildings = locations.filter(
            (x: QueueChoiceVm) => x.outcomeParentId === primaryLocation!.id
          );
          setAllBuildings(
            buildings.length > 0
              ? buildings
              : [{ id: BLANKID, name: "No Buildings for this Site" }]
          );
          setAllRooms([{ id: BLANKID, name: "No Buildings for this Site" }]);
          setBuilding(BLANKID);
          setRoom(BLANKID);
        } else if (
          buildings.find((x: QueueChoiceVm) => x.id === primaryLocation!.id)
        ) {
          setRoom(BLANKID);
          setBuilding(primaryLocation!.id);
          setAllBuildings(
            locations.filter(
              (x: QueueChoiceVm) =>
                x.outcomeParentId === primaryLocation!.outcomeParentId
            )
          );
          setSite(primaryLocation!.outcomeParentId!);
          var rooms = locations.filter(
            (x: QueueChoiceVm) => x.outcomeParentId === primaryLocation!.id
          );
          rooms.length > 0
            ? setAllRooms(rooms)
            : setAllRooms([
                { id: BLANKID, name: "No Rooms for this Building" },
              ]);
        } else {
          setRoom(primaryLocation!.id);
          setBuilding(primaryLocation!.outcomeParentId!);
          var b = buildings.find(
            (x: QueueChoiceVm) => x.id === primaryLocation!.outcomeParentId!
          );
          if (typeof b !== undefined) {
            //@ts-ignore
            var site = sites.find((x: QueueChoiceVm) => x.id === b.outcomeParentId!
            ).id!;
            setSite(site);
            setAllBuildings(
              locations.filter((x: QueueChoiceVm) => x.outcomeParentId === site)
            );
            var rooms = locations.filter(
              (x: QueueChoiceVm) => x.outcomeParentId === b!.id
            );
            setAllRooms(
              locations.filter(
                (x: QueueChoiceVm) => x.outcomeParentId === b!.id
              )
            );
          }
        }
      }
    }
  };

  const filterQuestionnaires = (data: QueueChoiceVm[]) => {
    data = data.filter(
      (item: QueueChoiceVm) =>
        item.outcomeIdentifier !== OutcomeIdentifier.Hobart_Covid_Questionnaire
    );
    data = data.filter(
      (item: QueueChoiceVm) =>
        item.outcomeIdentifier !==
        OutcomeIdentifier.Port_Pirie_Covid_Questionnaire
    );
    return data;
  };

  const filterVisiting = (data: QueueChoiceVm[]) => {
    data = data.filter(
      (item: QueueChoiceVm) =>
        item.outcomeIdentifier !== OutcomeIdentifier.Hobart_Visiting
    );
    data = data.filter(
      (item: QueueChoiceVm) =>
        item.outcomeIdentifier !== OutcomeIdentifier.Port_Pirie_visiting
    );
    return data;
  };

  const filterOther = (data: QueueChoiceVm[]) => {
    data = data.filter(
      (item: QueueChoiceVm) =>
        item.outcomeIdentifier !== OutcomeIdentifier.Hobart_Other
    );
    data = data.filter(
      (item: QueueChoiceVm) =>
        item.outcomeIdentifier !== OutcomeIdentifier.Port_Pirie_Other
    );
    return data;
  };

  const filterDontKnow = (data: QueueChoiceVm[]) => {
    data = data.filter(
      (item: QueueChoiceVm) =>
        item.outcomeIdentifier !== OutcomeIdentifier.Hobart_I_Do_Not_Know
    );
    data = data.filter(
      (item: QueueChoiceVm) =>
        item.outcomeIdentifier !== OutcomeIdentifier.Port_Pirie_I_Do_Not_Know
    );
    return data;
  };

  useEffect(() => {
    run();
  }, [props.departments]);

  return (
    <Slide in={true} direction={"right"}>
      <Container
        component="main"
        maxWidth="sm"
        style={{ marginBottom: "60px", marginTop: "50px" }}
      >
        <div className={classes.paper}>
          <img className={classes.image} src={cachedImg} alt="Site Logo" />
          <Typography className={classes.heading}>Update Details</Typography>
          <ValidatedForm>
            <ValidationTextField
              error={!formErrors.firstName.valid}
              helperText={formErrors.firstName.errorMessage}
              margin="normal"
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              value={firstName}
              autoFocus
              required
              onChange={(e: any) => {
                setFirstName(e.target.value);
              }}
              variant="outlined"
              disabled={loadingSave || loadingCancel}
            />
            <ValidationTextField
              error={!formErrors.lastName.valid}
              helperText={formErrors.lastName.errorMessage}
              margin="normal"
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              value={lastName}
              required
              onChange={(e: any) => {
                setLastName(e!.target.value);
              }}
              variant="outlined"
              validation={{ required: true }}
              disabled={loadingSave || loadingCancel}
            />
            <ValidationTextField
              variant="outlined"
              error={!formErrors.mobile.valid}
              helperText={
                !formErrors.mobile.valid ? formErrors.mobile.errorMessage : ""
              }
              margin="normal"
              fullWidth
              id="mobile"
              label="Phone number (mobile preferred)"
              name="mobileNumber"
              value={contactNumber}
              required
              onChange={(e: any) => {
                setContactNumber(e.target.value);
              }}
              type={"tel"}
              disabled={loadingSave || loadingCancel}
            />
            <FormControl fullWidth>
              <div style={{ marginLeft: "110px!important" }}>
                <InputLabel
                  className={classes.selectField}
                  error={!formErrors.department.valid}
                  style={{ marginLeft: "110px!important" }}
                  id="demo-simple-select-label"
                >
                  <div
                    style={{
                      left: "16px",
                      position: "absolute",
                      width: "500px",
                      top: "-9px",
                    }}
                  >
                    Your Department *
                  </div>
                </InputLabel>
              </div>
              <Select
                className={classes.selectField}
                error={!formErrors.department.valid}
                variant="outlined"
                fullWidth
                required={true}
                value={department}
                label="Your Deparment *"
                onChange={handleDepartmentChange}
                disabled={loadingSave || loadingCancel}
              >
                {state.user?.departments &&
                state.user?.departments.length > 0 &&
                departmentList &&
                departmentList.length > 0 ? (
                  departmentList.map((element: TeamViewModel) => {
                    return (
                      <MenuItem value={element.id}>{element.name}</MenuItem>
                    );
                  })
                ) : (
                  <MenuItem value={"7a544ac3-a7d0-4c3c-8837-22ee9c38d5C1"}>
                    Departments not found
                  </MenuItem>
                )}
              </Select>
              <FormHelperText className={classes.error}>
                {!formErrors.department.valid
                  ? formErrors.department.errorMessage
                  : ""}
              </FormHelperText>
            </FormControl>
            <Divider
              variant="middle"
              style={{ marginTop: "0px", marginBottom: "30px" }}
            />
            <Typography className={classes.heading}>
              Update Primary Location
            </Typography>
            <FormControl fullWidth>
              <div id={"Site"}>
                <InputLabel
                  className={classes.selectFieldSite}
                  error={!formErrors.site.valid}
                  id="Site-label"
                >
                  <div
                    style={{
                      left: "16px",
                      position: "absolute",
                      width: "500px",
                      top: "-7px",
                    }}
                  >
                    Site *
                  </div>
                </InputLabel>
                <Select
                  error={!formErrors.site.valid}
                  className={classes.selectFieldSite}
                  variant="outlined"
                  fullWidth
                  id="Site-select"
                  value={site}
                  label="Site *"
                  onChange={handleSiteChange}
                  disabled={loadingSave || loadingCancel}
                >
                  {allBuildings.length > 0 ? (
                    allSites.map((element: any) => {
                      return (
                        <MenuItem value={element.id}>{element.name}</MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={"7a544ac3-a7d0-4c3c-8837-22ee9c38d5C1"}>
                      Site not found
                    </MenuItem>
                  )}
                </Select>
                <FormHelperText className={classes.error}>
                  {!formErrors.site.valid ? formErrors.site.errorMessage : ""}
                </FormHelperText>
              </div>
            </FormControl>

            <FormControl fullWidth>
              <div id={"building"}>
                <InputLabel
                  className={classes.selectFieldOne}
                  error={!formErrors.building.valid}
                  id="building-label"
                >
                  <div
                    style={{
                      left: "16px",
                      position: "absolute",
                      width: "500px",
                      top: "-7px",
                    }}
                  >
                    Building *
                  </div>
                </InputLabel>
                <Select
                  error={!formErrors.building.valid}
                  className={classes.selectFieldOne}
                  variant="outlined"
                  fullWidth
                  id="building-select"
                  value={building}
                  label="building *"
                  onChange={handleBuildingChange}
                  disabled={loadingSave || loadingCancel}
                >
                  {allBuildings.length > 0 ? (
                    allBuildings.map((element: any) => {
                      return (
                        <MenuItem value={element.id}>{element.name}</MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={"7a544ac3-a7d0-4c3c-8837-22ee9c38d5C1"}>
                      buildings not found
                    </MenuItem>
                  )}
                </Select>
                <FormHelperText className={classes.error}>
                  {!formErrors.building.valid
                    ? formErrors.building.errorMessage
                    : ""}
                </FormHelperText>
              </div>
            </FormControl>

            <FormControl fullWidth>
              <div id={"room"}>
                <InputLabel
                  className={classes.selectFieldtwo}
                  error={!formErrors.room.valid}
                  id="room-label"
                >
                  <div
                    style={{
                      left: "16px",
                      position: "absolute",
                      width: "500px",
                      top: "-7px",
                    }}
                  >
                    Room *
                  </div>
                </InputLabel>
                <Select
                  error={!formErrors.room.valid}
                  className={classes.selectFieldtwo}
                  variant="outlined"
                  fullWidth
                  id="room-select"
                  value={room}
                  label="room *"
                  onChange={handleRoomChange}
                  disabled={loadingSave || loadingCancel}
                >
                  {allBuildings.length > 0 ? (
                    allRooms.map((element: any) => {
                      return (
                        <MenuItem value={element.id}>{element.name}</MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={"7a544ac3-a7d0-4c3c-8837-22ee9c38d5C1"}>
                      room not found
                    </MenuItem>
                  )}
                </Select>
                <FormHelperText className={classes.error}>
                  {!formErrors.room.valid ? formErrors.room.errorMessage : ""}
                </FormHelperText>
              </div>
            </FormControl>

            <Divider
              variant="middle"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            />
            <Button
              disabled={loadingSave}
              type="submit"
              fullWidth
              variant="contained"
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                event.preventDefault();
                setLoadingSave(true);
                onSubmit();
              }}
            >
              {loadingSave && <CircularProgress style={{ color: "white" }} />}
              {!loadingSave && "Save"}
            </Button>
            <Divider
              variant="middle"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            />
            <Button
              disabled={loadingCancel}
              type="submit"
              fullWidth
              variant="contained"
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                event.preventDefault();
                setLoadingCancel(true);
                props.onCancel();
              }}
            >
              {loadingCancel && <CircularProgress style={{ color: "white" }} />}
              {!loadingCancel && "Cancel"}
            </Button>
          </ValidatedForm>
        </div>
        <Divider variant="middle" style={{ marginTop: "-10px" }} />
      </Container>
    </Slide>
  );
};
