import { Drawer } from "../../../../components/muiCore/Drawer";
import { AppBar } from "../../../../components/muiCore/Paper";
import { IconButton, Toolbar, useTheme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { QuestionHeader } from "../../../../components/core/QuestionSlider/QuestionItemHeader";
import { UserRegistration } from "../../../../components/core/forms/UserRegistration";
import { Questionnaire } from "../../../../components/core/QuestionSlider/Questionnaire";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { useApiClient } from "../../../../common/apiclients";
import {
  LoginUserViewModel,
  QuestionnaireClient,
  QuestionnairesClient,
  QuestionValidationError,
} from "../../../../common/client";
import { IQuestion } from "../../../../models/Question";
import {
  autoSelectChoice,
  makeQuestionnaireControl,
  parseAnswers,
  QuestionnaireControls,
} from "../../../../components/core/Questionnaire";

export function QueueQuestionnaireDrawer({
  questionnaireSideDrawer,
  questionnaireResponseId,
  drawerTitle,
  formFinalised,
  show,
  onClose,
  registerFormUser,
  allFormDetails,
}: {
  show: boolean;
  drawerTitle: string;
  questionnaireResponseId?: string;
  questionnaireSideDrawer: MutableRefObject<any>;
  formFinalised: () => void;
  registerFormUser: (user: LoginUserViewModel) => void;
  onClose: (event: any, reason: string) => void;
  allFormDetails: boolean;
}) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const questionClient = useApiClient(QuestionnaireClient);
  const adminQuestionClient = useApiClient(QuestionnairesClient);

  const [questionsAndControls, setQuestionsAndControls] =
    useState<{ questions: IQuestion[]; controls: QuestionnaireControls }>();
  const [referralPerson, setReferralPerson] = useState<string>();
  const [showStepper, setShowStepper] = useState(false);

  const [errors, setErrors] = useState<QuestionValidationError[]>();
  const [validateAll, setValidateAll] = useState(false);
  const currentPageRef = useRef(0);

  async function handleQuestionnaireResponse(questionIndex: number) {
    if (questionsAndControls) {
      const controls = questionsAndControls.controls;
      if (controls.dirty) {
        const question = questionsAndControls.questions[questionIndex];
        const questionId = question.id;
        const { choiceId: _choiceId, value } =
          controls.fields[questionId].toObject();
        const choiceId = _choiceId ?? autoSelectChoice(question);
        if (choiceId) {
          const valueAndChoice = {
            choiceId,
            value: value ? JSON.stringify(value) : undefined,
          };
          try {
            await questionClient.addQuestionChoiceByAdmin(
              questionnaireResponseId!,
              questionId,
              referralPerson!,
              valueAndChoice
            );
          } catch (e) {
            enqueueSnackbar("Failed to save response: " + question.heading, {
              variant: "error",
            });
          }
        }
        if (validateAll) {
          await validate();
        }
        controls.markAsClean();
      }
    }
  }

  async function closeQuestionnaire() {
    await handleQuestionnaireResponse(currentPageRef.current);
    onClose({}, "closed");
  }

  async function loadQuestionnaire(id: string) {
    const { questions, responses, personId, showStepper } =
      await adminQuestionClient.getQuestions(id);
    const controls = makeQuestionnaireControl(
      questions,
      parseAnswers(responses)
    );
    setQuestionsAndControls({ questions, controls });
    setValidateAll(false);
    setErrors(undefined);
    setReferralPerson(personId);
    setShowStepper(showStepper);
    
    questionnaireSideDrawer.current?.openDrawer();
  }

  useEffect(() => {
    if (show) {
      if (questionnaireResponseId) {
        loadQuestionnaire(questionnaireResponseId);
      } else {
        setReferralPerson(undefined);
        questionnaireSideDrawer.current?.openDrawer();
      }
    } else {
      setQuestionsAndControls(undefined);
    }
  }, [show, questionnaireResponseId]);

  return (
    <Drawer
      variant={"temporary"}
      ref={questionnaireSideDrawer}
      anchor={"right"}
      onClose={onClose}
      title={drawerTitle}
    >
      <AppBar
        style={{
          borderRadius: 0,
          marginBottom: "0px",
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={closeQuestionnaire}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {!referralPerson ? (
        <>
          <QuestionHeader index={0} text={"Referral Information"} />
          <UserRegistration
            onSubmit={(isFormValid, user) => {
              registerFormUser(user);
            }}
            allDetails={allFormDetails}
          />{" "}
        </>
      ) : (
        questionsAndControls && (
          <Questionnaire
            questions={questionsAndControls.questions}
            validation={errors ?? []}
            controls={questionsAndControls.controls}
            onSaveResponse={handleQuestionnaireResponse}
            onQuestionnaireFinalised={formFinalised}
            maxWidth={"100%"}
            overrideSubmit={saveAndValidate}
            currentPageRef={currentPageRef}
            stepper={showStepper}
          />
        )
      )}
    </Drawer>
  );

  async function saveAndValidate() {
    return await validate();
  }

  async function validate() {
    const errors = await questionClient.validateQuestionnaire(
      questionnaireResponseId!
    );
    setValidateAll(true);
    setErrors(errors);
    questionsAndControls!.controls.setTouched(true);
    const showErrors = errors.length > 0;
    return !showErrors;
  }
}
