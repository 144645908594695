import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Questionnaire } from "../../../components/core/QuestionSlider/Questionnaire";
import { IQuestion } from "../../../models/Question";
import { useSnackbar } from "notistack";
import { useApiClient } from "../../../common/apiclients";
import {
  BasicQuestionnaireViewModel,
  KeyValuePairOfGuidAndString,
  QuestionnaireClient,
  QuestionnaireEditModel,
  QuestionnairesClient,
  QuestionValidationError,
} from "../../../common/client";
import {
  makeQuestionnaireControl,
  parseAnswers,
  QuestionnaireControls,
} from "../../../components/core/Questionnaire";
import {
  buildGroup,
  control,
  ControlType,
  FormControl,
  useControlValue,
} from "@react-typed-forms/core";
import Box from "@material-ui/core/Box";
import { FTextField } from "../../../common/formComponents/FTextField";
import { Button, CircularProgress, Grid, MenuItem } from "@material-ui/core";
import { QuestionnaireFormDef } from "./Questionnaire/QuestionnaireEditor";
import { toQuestionForm } from "./Questionnaire/QuestionEditor";
import { FSelectAutocomplete } from "../../../common/formComponents/FSelectAutocomplete";
import { useDebounce } from "../../../common/debounce";

interface Form {
  userId?: KeyValuePairOfGuidAndString;
  questionnaireId?: string;
  questionnaireResponseId?: KeyValuePairOfGuidAndString;
}

const FormDef = buildGroup<Form>()({
  userId: control(undefined),
  questionnaireId: control(undefined),
  questionnaireResponseId: control(undefined),
});

export const Temp = () => {
  const qclient = useApiClient(QuestionnaireClient);
  const qsclient = useApiClient(QuestionnairesClient);
  const [form] = useState(FormDef);
  const { userId, questionnaireResponseId, questionnaireId } = form.fields;
  const [questionsAndControls, setQuestionsAndControls] =
    useState<{ questions: IQuestion[]; controls: QuestionnaireControls }>();
  const [errors, setErrors] = useState<QuestionValidationError[]>();
  const [questionnaires, setQuestionnaires] =
    useState<BasicQuestionnaireViewModel[]>();
  const { enqueueSnackbar } = useSnackbar();
  const importRef = useRef<HTMLInputElement | null>(null);
  const [validateAll, setValidateAll] = useState(false);

  useEffect(() => {
    loadQuestionnaireList();
  }, []);

  async function handleQuestionnaireResponse(questionIndex: number) {
    if (questionsAndControls) {
      const controls = questionsAndControls.controls;
      const questionId = questionsAndControls.questions[questionIndex].id;
      const { choiceId, value } = controls.fields[questionId].toObject();
      const valueAndChoice = {
        choiceId: choiceId!,
        value: value ? JSON.stringify(value) : undefined,
      };
      await qclient.addQuestionChoiceByAdmin(
        questionnaireResponseId.value!.key,
        questionId,
        userId.value!.key,
        valueAndChoice
      );
      if (validateAll) {
        await validate();
      }
      enqueueSnackbar("Response Saved.", { variant: "success" });
    }
  }

  async function handleQuestionnaireFinalisation() {
    // await qclient.finaliseQuestionnaireByAdmin(
    //   questionnaireResponseId.value!.key,
    //   userId.value!.key
    // );
    enqueueSnackbar("FINALISE QUESTIONNAIRE", { variant: "info" });
  }

  return (
    <Box>
      <Box>
        <Grid container>
          <Grid item xs={4}>
            <PersonSelector userId={userId} />
          </Grid>
          <Grid item xs={4}>
            {questionnaires ? (
              <FTextField
                state={questionnaireId}
                label={"Questionnaire"}
                select
                fullWidth
              >
                {questionnaires.map((q) => (
                  <MenuItem value={q.id}>{q.name}</MenuItem>
                ))}
              </FTextField>
            ) : (
              <CircularProgress />
            )}
          </Grid>
          <Grid item xs={4}>
            <QuestionnaireSelector
              userIdControl={userId}
              questionnaireControl={questionnaireId}
              questId={questionnaireResponseId}
            />
          </Grid>
        </Grid>
        <ActionButtons form={form} />
      </Box>
      {questionsAndControls && (
        <Questionnaire
          key={questionnaireResponseId.value!.key}
          validation={errors}
          questions={questionsAndControls.questions}
          controls={questionsAndControls.controls}
          onSaveResponse={handleQuestionnaireResponse}
          onQuestionnaireFinalised={handleQuestionnaireFinalisation}
          maxWidth={"100%"}
          overrideSubmit={saveAndValidate}
        />
      )}
    </Box>
  );

  async function saveAndValidate() {
    return await validate();
  }

  async function validate() {
    const errors = await qclient.validateQuestionnaire(
      questionnaireResponseId.value!.key
    );
    setValidateAll(true);
    setErrors(errors);
    const showErrors = errors.length > 0;
    return !showErrors;
  }

  function ActionButtons({
    form: { fields },
  }: {
    form: ControlType<typeof FormDef>;
  }) {
    const hasPerson = Boolean(useControlValue(fields.userId)?.key);
    const hasQuestionnaire = Boolean(useControlValue(fields.questionnaireId));
    const hasResponse = Boolean(
      useControlValue(fields.questionnaireResponseId)
    );
    return (
      <Box my={1}>
        {/*<Button onClick={preFill}>Pre-fill</Button>{" "}*/}
        <Button
          onClick={createNewQuestionnaire}
          disabled={!hasPerson || !hasQuestionnaire}
        >
          New
        </Button>{" "}
        <Button onClick={loadQuestionnaire} disabled={!hasResponse}>
          Load
        </Button>{" "}
        <Button onClick={writeCsv}>Write CSV</Button>{" "}
        <Button onClick={exportQuestionnaire}>Export Questionnaire</Button>{" "}
        <Button onClick={() => importRef.current?.click()}>
          Import Questionnaire
        </Button>
        <input
          ref={importRef}
          type="file"
          onChange={doImport}
          style={{ display: "none" }}
        />
      </Box>
    );
  }
  async function loadQuestionnaire() {
    const d = await qsclient.getQuestions(questionnaireResponseId.value!.key);
    const answers = parseAnswers(d.responses);
    const controls = makeQuestionnaireControl(d.questions);
    controls.setValue(answers);
    setQuestionsAndControls({ questions: d.questions, controls });
    questionnaireId.setValue(d.questionnaireId);
    setErrors(undefined);
    setValidateAll(false);
  }

  async function createNewQuestionnaire() {
    const newResp = await qclient.createNewResponse(
      questionnaireId.value!,
      userId.value!.key
    );
    questionnaireResponseId.setValue({ key: newResp.id, value: "New one" });
    await loadQuestionnaire();
  }

  // async function preFill() {
  //   const newResp = await qsclient.startQuestionnaire(
  //     userId.value,
  //     questionnaireId.value
  //   );
  //   questionnaireResponseId.setValue(newResp);
  //   await loadQuestionnaire();
  // }

  async function loadQuestionnaireList() {
    const allQ = await qsclient.getQuestionnaires();
    setQuestionnaires(allQ);
  }

  async function writeCsv() {
    const result = await qsclient.getExports(questionnaireId.value);
    saveAs(result.data, "something.csv");
  }

  async function exportQuestionnaire() {
    const result = await qsclient.getQuestionnaire(questionnaireId.value!);
    const form = QuestionnaireFormDef();
    form.setValue({
      ...result,
      questions: result.questions.map(toQuestionForm),
      audiences: result.audiences.map((a) => a.audienceId),
    });
    const b = new Blob([JSON.stringify(form.toObject(), null, 2)]);
    saveAs(b, result.name + ".json");
  }

  function doImport(e: ChangeEvent<HTMLInputElement>) {
    const files = e.target.files;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        importQuestionnaire(files[i]);
      }
    }
  }

  async function importQuestionnaire(file: File) {
    const qText = await file.text();
    const data: QuestionnaireEditModel = JSON.parse(qText);
    const result = await qsclient.createQuestionnaire(data);
    await loadQuestionnaireList();
  }
};

function PersonSelector({
  userId,
}: {
  userId: FormControl<KeyValuePairOfGuidAndString | undefined>;
}) {
  const [query] = useState(control(""));
  const [people, setPeople] = useState<KeyValuePairOfGuidAndString[]>();
  const [loading, setLoading] = useState(false);
  const queryText = useDebounce(useControlValue(query), 200);
  const questionnairesClient = useApiClient(QuestionnairesClient);
  useEffect(() => {
    if (queryText.length > 3) {
      doSearch();
    }
  }, [queryText]);
  return (
    <FSelectAutocomplete
      query={query}
      state={userId}
      label={"Person"}
      options={people ?? []}
      loading={loading}
      getOptionLabel={(p) => p.value}
    />
  );

  async function doSearch() {
    setLoading(true);
    setPeople(await questionnairesClient.adminSearchPerson(queryText));
    setLoading(false);
  }
}

function QuestionnaireSelector({
  questId,
  userIdControl,
  questionnaireControl,
}: {
  userIdControl: FormControl<KeyValuePairOfGuidAndString | undefined>;
  questionnaireControl: FormControl<string | undefined>;
  questId: FormControl<KeyValuePairOfGuidAndString | undefined>;
}) {
  const [query] = useState(control(""));
  const [questionnaires, setQuestionnaires] =
    useState<KeyValuePairOfGuidAndString[]>();
  const [loading, setLoading] = useState(false);
  const questionnairesClient = useApiClient(QuestionnairesClient);
  const userId = useControlValue(userIdControl);
  const qId = useControlValue(questionnaireControl);
  useEffect(() => {
    if (userId && qId) {
      doSearch(userId.key);
    } else {
      setQuestionnaires([]);
    }
  }, [userId, qId]);
  return (
    <FSelectAutocomplete
      query={query}
      state={questId}
      label={"Answers"}
      options={questionnaires ?? []}
      loading={loading}
      getOptionLabel={(p) => p.value}
    />
  );

  async function doSearch(userId: string) {
    setLoading(true);
    setQuestionnaires(
      await questionnairesClient.getQuestionnaireResponsesForUser(userId, qId)
    );
    setLoading(false);
  }
}
